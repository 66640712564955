import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Router } from '@angular/router'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { TranslateService } from '@ngx-translate/core'
import { Observable, lastValueFrom, map, startWith } from 'rxjs'
import { DialogFactoryService } from 'src/app/shared/components/dialog/dialog-factory.service'
import { DialogService } from 'src/app/shared/components/dialog/dialog.service'
import { APP_IMAGES } from 'src/app/shared/const/images.const'
import { APP_ROUTES } from 'src/app/shared/const/routes.const'
import { DialogData } from 'src/app/shared/models/dialog-data.model'
import { IEvent } from 'src/app/shared/models/events.model'
import { EventService } from 'src/app/shared/services/event.service'
import { FilterService } from 'src/app/shared/services/filter.service'
import { FAKE_EVENTS } from 'src/fake-data/events-data'

@Component({
  selector: 'app-mobile-search',
  templateUrl: './mobile-search.component.html',
  styleUrls: ['./mobile-search.component.scss']
})
export class MobileSearchComponent {
  @Input()
  set open(value: boolean) {
    if (value) {
      this.dispatchDialog()
    }
  }
  @Input() searchData: Array<any>
  @Output() closePopover = new EventEmitter<boolean>()

  public filteredSearch: Observable<Array<any>>
  mGlass = faMagnifyingGlass
  public searchCtrl = new FormControl()
  public readonly APP_IMAGES = APP_IMAGES
  public userImage: string
  public isLogged: boolean = true
  public calendarBox: boolean = true
  public search: any
  @Output() closing = new EventEmitter<boolean>()
  @Output() login = new EventEmitter<boolean>()
  @ViewChild('mobileSearchDialogTemplate')
  mobileSearchDialogTemplate: TemplateRef<any>
  private dialog: DialogService
  public featuredEvents: Array<IEvent> = FAKE_EVENTS
  public eventsFiltered: any[] = []
  private searchList: Array<any> = []

  constructor(
    private router: Router,
    private dialogFactoryService: DialogFactoryService,
    public translate: TranslateService,
    public eventService: EventService,
    public filterService: FilterService
  ) {}

  public ngOnInit() {
    this.getEvents()
    this.searchList = this.eventsFiltered
    this.searchData = this.searchList

    this.filteredSearch = this.searchCtrl.valueChanges.pipe(
      startWith(''),
      map(search => {
        if (search) {
          this.filterSearch(search)
        }
        return this.searchList.slice()
      })
    )
  }

  private async getEvents() {
    let picture = APP_IMAGES.LOGO
    lastValueFrom(await this.eventService.getAllEvents()).then(res => {
      for (const evento of res.event) {
        if (evento.picture !== null) {
          picture = evento.picture.url
        }
        const event2Show = {
          id: evento.id,
          imageUrl: picture,
          headerUrl: picture,
          title: evento.name,
          dateText: new Date(evento.initDate).toLocaleString('es-ES', {
            year: 'numeric',
            day: 'numeric',
            month: 'short',
            weekday: 'short'
          }),
          date: new Date(evento.initDate).toLocaleDateString('es-ES', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
          }),
          time: evento.initTime,
          place: evento.enclosure.name,
          city: evento.enclosure.address.city,
          address: evento.enclosure.address,
          priceFrom: 10,
          tags: evento.tags,
          description: evento.description,
          sessions: evento.sessions
        }
        this.eventsFiltered.push(event2Show)
      }
    })
  }

  private filterSearch(value: string): any {
    const filterValue = value.toLowerCase()

    const result = this.searchData.filter(search =>
      search.title.toLowerCase().includes(filterValue)
    )
    this.searchList = result
    return result
  }

  public searchF(): void {
    /*console.log("valorrrrrr"+this.search);


      //this.filteredSearch = this.filterSearch(this.search);
      this.filteredSearch = this.search.valueChanges.pipe(
        startWith(''),
        map(search => (search ? this.filterSearch(search) : this.searchList.slice()))
      )




    /*this.filteredSearch = this.searchCtrl.valueChanges.pipe(
      startWith(''),
      map(search => (search ? this.filterSearch(search) : this.searchList.slice()))
    )*/
  }
  public goToEvent(eventoId: number) {
    this.filterSearch('')
    this.router.navigate([APP_ROUTES.EVENT_DETAIL, eventoId])
    this.dialog.closeDialog()
  }

  private openDialog(dialogData: DialogData): void {
    this.dialog = this.dialogFactoryService.open(dialogData)
    this.closeDialogAction()
  }

  private closeDialogAction(): void {
    this.dialog.close().subscribe(data => {
      this.closing.emit(true)
    })
  }

  private dispatchDialog() {
    this.openDialog({
      template: this.mobileSearchDialogTemplate
    })
  }

  goToAllEventsSearched() {
    this.dialog.closeDialog()
    this.router.navigate([APP_ROUTES.FILTER], {
      state: { loadfilter: this.searchList }
    })
  }
}
