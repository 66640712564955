import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class EventService {
  constructor(private _http: HttpClient, private snackBar: MatSnackBar, private router: Router) {}

  public async getAllEvents(): Promise<Observable<any>> {
    return this._http.get(environment.apiUrl + '/events')
  }

  public async getEvent(id: string): Promise<Observable<any>> {
    return this._http.get(environment.apiUrl + '/events/' + id)
  }

  public async getEventWithOutEnclousure(id: string): Promise<Observable<any>> {
    return this._http.get(environment.apiUrl + '/events/findoneWithOutEnclousure/' + id)
  }

  public async getFeaturedEvents(): Promise<Observable<any>> {
    return this._http.get(environment.apiUrl + '/events/featured/events')
  }

  public async getMostSoldEvents(): Promise<Observable<any>> {
    return this._http.get(environment.apiUrl + '/admin/orders/find4BestSellers')
  }

  public async getEventsByCategory(eventCategory: any): Promise<Observable<any>> {
    return this._http.get(environment.apiUrl + '/events/eventByCategoryName/' + eventCategory)
  }

  public async getEventsByCategory2(eventCategory: any): Promise<Observable<any>> {
    return this._http.get(environment.apiUrl + '/events/eventByCategoryName2/' + eventCategory)
  }

  public async getEventsBySubCategory(subCategory: any): Promise<Observable<any>> {
    return this._http.get(environment.apiUrl + '/events/eventBySubcategoryName/' + subCategory)
  }

  public async getEventsBySubCategory2(subCategory: any): Promise<Observable<any>> {
    return this._http.get(environment.apiUrl + '/events/eventBySubcategoryName2/' + subCategory)
  }

  public async getEventsByCity(city: any): Promise<Observable<any>> {
    return this._http.get(environment.apiUrl + '/events/eventByCity/' + city)
  }

  public async getEventsByLocation(location: any): Promise<Observable<any>> {
    return this._http.get(environment.apiUrl + '/events/eventByLocation/' + location)
  }
}
