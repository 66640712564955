<ng-template #mobileMenuDialogTemplate>
  <div class="login-dialog">
    <h3 class="login-dialog__title">
      <fa-icon class="chevron-icon" [icon]="chevronLeft" (click)="onCloseMenu()"></fa-icon>
      {{ 'MENU' }}
    </h3>
    <mat-divider class="divider dark"></mat-divider>
    <div *ngFor="let categ of categories" (click)="showSubMenus(categ)">
      <span class="full-width" *ngIf="isExpanded || isShowing">{{ categ.name }}</span>
      <mat-icon
        class="menu-button"
        [ngClass]="{ rotated: showSubmenu }"
        *ngIf="isExpanded || isShowing"
        >expand_more</mat-icon
      >
      <div
        class="submenu"
        [ngClass]="{ expanded: showSubmenu }"
        *ngIf="(isShowing || isExpanded) && categorySelected === categ.name">
        <p
          *ngFor="let subcategory of categ.subcategories"
          (click)="getEventsSubByCategory(subcategory.name)">
          {{ subcategory.name }}
        </p>
      </div>
    </div>
    <!-- <div>
      <mat-list-item (click)="showMusicSubmenu = !showMusicSubmenu" class="parent">
        <span class="full-width" *ngIf="isMusicExpanded || isShowing">Conciertos</span>
        <mat-icon
          class="menu-button"
          [ngClass]="{ rotated: showMusicSubmenu }"
          *ngIf="isMusicExpanded || isShowing"
          >expand_more</mat-icon
        >
      </mat-list-item>
      <div
        class="submenu"
        [ngClass]="{ expanded: showMusicSubmenu }"
        *ngIf="isShowing || isMusicExpanded">
        <p>Pop/Rock</p>
        <p>Urban/Latino</p>
        <p>Dance/Electrónica</p>
        <p>Indie/Alternativo</p>
        <p>Clásica</p>
        <p>Jazz/Folk</p>
        <p>Otros</p>
        <p>Todos Música</p>
      </div>
    </div>

    <div>
      <mat-list-item (click)="showArtsSubmenu = !showArtsSubmenu" class="parent">
        <span class="full-width" *ngIf="isArtsExpanded || isArtsShowing">Artes escénicas</span>
        <mat-icon
          class="menu-button"
          [ngClass]="{ rotated: showArtsSubmenu }"
          *ngIf="isArtsExpanded || isArtsShowing"
          >expand_more</mat-icon
        >
      </mat-list-item>
    </div>
    <div
      class="submenu"
      [ngClass]="{ expanded: showArtsSubmenu }"
      *ngIf="isArtsShowing || isArtsExpanded">
      <p>Teatro</p>
      <p>Ballet/Danza</p>
      <p>Ópera/Zarzuela</p>
      <p>Magia</p>
      <p>Musicales</p>
      <p>Humor</p>
      <p>Todos Artes Escénicas</p>
    </div>
    <div>
      <mat-list-item (click)="showFestivalsSubmenu = !showFestivalsSubmenu" class="parent">
        <span class="full-width" *ngIf="isFestivalsExpanded || isFestivalsShowing">Festivales</span>
        <mat-icon
          class="menu-button"
          [ngClass]="{ rotated: showFestivalsSubmenu }"
          *ngIf="isFestivalsExpanded || isFestivalsShowing"
          >expand_more</mat-icon
        >
      </mat-list-item>
    </div>
    <div
      class="submenu"
      [ngClass]="{ expanded: showFestivalsSubmenu }"
      *ngIf="isFestivalsShowing || isFestivalsExpanded">
      <p>Todos Festivales</p>
    </div>
    <div>
      <mat-list-item (click)="showSportsSubmenu = !showSportsSubmenu" class="parent">
        <span class="full-width" *ngIf="isSportsExpanded || isSportsShowing">Deportes</span>
        <mat-icon
          class="menu-button"
          [ngClass]="{ rotated: showSportsSubmenu }"
          *ngIf="isSportsExpanded || isSportsShowing"
          >expand_more</mat-icon
        >
      </mat-list-item>
    </div>
    <div
      class="submenu"
      [ngClass]="{ expanded: showSportsSubmenu }"
      *ngIf="isSportsShowing || isSportsExpanded">
      <p>Fútbol</p>
      <p>Baloncesto</p>
      <p>Tenis</p>
      <p>Motor</p>
      <p>Otros</p>
      <p>Todos Deportes</p>
    </div>
    <div>
      <mat-list-item (click)="showFamilySubmenu = !showFamilySubmenu" class="parent">
        <span class="full-width" *ngIf="isFamilyExpanded || isFamilyShowing">Familias</span>
        <mat-icon
          class="menu-button"
          [ngClass]="{ rotated: showFamilySubmenu }"
          *ngIf="isFamilyExpanded || isFamilyShowing"
          >expand_more</mat-icon
        >
      </mat-list-item>
    </div>
    <div
      class="submenu"
      [ngClass]="{ expanded: showFamilySubmenu }"
      *ngIf="isFamilyExpanded || isFamilyShowing">
      <p>Magia</p>
      <p>Circo</p>
      <p>Espectáculos Infantiles</p>
      <p>Actividades/Talleres</p>
      <p>Parques temáticos</p>
      <p>Todos Familiares</p>
    </div>
    <div>
      <mat-list-item (click)="showCultureSubmenu = !showCultureSubmenu" class="parent">
        <span class="full-width" *ngIf="isCultureExpanded || isCultureShowing">Cultura y más</span>
        <mat-icon
          class="menu-button"
          [ngClass]="{ rotated: showCultureSubmenu }"
          *ngIf="isCultureExpanded || isCultureShowing"
          >expand_more</mat-icon
        >
      </mat-list-item>
    </div>
    <div
      class="submenu"
      [ngClass]="{ expanded: showCultureSubmenu }"
      *ngIf="isCultureShowing || isCultureExpanded">
      <p>Museos/Exposiciones</p>
      <p>Ferias/Congresos</p>
      <p>Conferencias/Presentaciones</p>
      <p>Tours/visitas</p>
      <p>Eventos/Fiestas</p>
      <p>Otros</p>
      <p>Todos Cultura y más</p>
    </div> -->
  </div>
</ng-template>
