import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { APP_ROUTES } from './shared/const/routes.const'

const routes: Routes = [
  {
    path: APP_ROUTES.HOME,
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
  },
  {
    path: APP_ROUTES.EVENT_DETAIL,
    loadChildren: () =>
      import('./modules/event-detail/event-detail.module').then(m => m.EventDetailModule)
  },
  {
    path: APP_ROUTES.VIEW_SEATMAP,
    loadChildren: () =>
      import('./modules/view-seatmap/view-seatmap.module').then(m => m.ViewSeatmapModule)
  },
  {
    path: APP_ROUTES.BUY_TICKETS,
    loadChildren: () =>
      import('./modules/buy-tickets/buy-tickets.module').then(m => m.BuyTicketsModule)
  },
  {
    path: APP_ROUTES.SELL_TICKETS,
    loadChildren: () =>
      import('./modules/sell-tickets/sell-tickets.module').then(m => m.SellTicketsModule)
  },
  {
    path: APP_ROUTES.PAYMENT_CONFIRMATION,
    loadChildren: () =>
      import('./modules/payment-confirmation/payment-confirmation.module').then(
        m => m.PaymentConfirmationModule
      )
  },
  {
    path: APP_ROUTES.PAYMENT_CONFIRMATION_ERROR,
    loadChildren: () =>
      import(
        './modules/payment-confirmation-error/payment-confirmation-error/payment-confirmation-error.module'
      ).then(m => m.PaymentConfirmationErrorModule)
  },
  {
    path: APP_ROUTES.FILTER,
    loadChildren: () => import('./modules/filter/filter.module').then(m => m.FilterModule)
  },
  {
    path: APP_ROUTES.HELP,
    loadChildren: () => import('./modules/help/help.module').then(m => m.HelpModule)
  },
  {
    path: APP_ROUTES.ADVERTISING,
    loadChildren: () =>
      import('./modules/advertising/advertising.module').then(m => m.AdvertisingModule)
  },
  {
    path: APP_ROUTES.SELL_WITH_US,
    loadChildren: () =>
      import('./modules/sell-with-us/sell-with-us.module').then(m => m.SellWithUsModule)
  },
  {
    path: APP_ROUTES.USE_TERMS,
    loadChildren: () => import('./modules/use-terms/use-terms.module').then(m => m.UseTermsModule)
  },
  {
    path: APP_ROUTES.PRIVACY_POLICY,
    loadChildren: () =>
      import('./modules/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
  },
  {
    path: APP_ROUTES.ABOUT_US,
    loadChildren: () => import('./modules/about-us/about-us.module').then(m => m.AboutUsModule)
  },
  {
    path: APP_ROUTES.HELP,
    loadChildren: () => import('./modules/help/help.module').then(m => m.HelpModule)
  },
  {
    path: APP_ROUTES.PROFILE,
    loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: APP_ROUTES.RESET,
    loadChildren: () =>
      import('./modules/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {
    path: APP_ROUTES.COOKIES,
    loadChildren: () => import('./modules/cookies/cookies.module').then(m => m.CookiesModule)
  },
  {
    path: APP_ROUTES.SELL_TICKETS,
    loadChildren: () =>
      import('./modules/sell-tickets/sell-tickets.module').then(m => m.SellTicketsModule)
  },
  {
    path: '',
    redirectTo: APP_ROUTES.HOME,
    pathMatch: 'full'
  },
  {
    path: APP_ROUTES.LEGAL,
    loadChildren: () => import('./modules/legal/legal.module').then(m => m.LegalModule)
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'enabledBlocking',
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
