import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core'
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { TranslateService } from '@ngx-translate/core'
import { DialogFactoryService } from 'src/app/shared/components/dialog/dialog-factory.service'
import { DialogService } from 'src/app/shared/components/dialog/dialog.service'
import { APP_IMAGES } from 'src/app/shared/const/images.const'
import { DialogData } from 'src/app/shared/models/dialog-data.model'
import { UserService } from 'src/app/shared/services/user.service'

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit {
  @Input()
  set open(value: boolean) {
    if (value) {
      this.dispatchDialog()
    }
  }
  @Output() closing = new EventEmitter<boolean>()
  @Output() login = new EventEmitter<boolean>()
  @ViewChild('recoveryDialogTemplate')
  recoveryDialogTemplate: TemplateRef<any>

  get fc(): { [key: string]: AbstractControl } {
    return this.recoveryForm.controls
  }

  public readonly APP_IMAGES = APP_IMAGES
  public recoveryForm: FormGroup
  public isSubmitted: boolean = false

  private dialog: DialogService
  private token: string

  constructor(
    private dialogFactoryService: DialogFactoryService,
    public translate: TranslateService,
    private fb: FormBuilder,
    private userService: UserService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.initForm()
  }

  private initForm(): void {
    this.recoveryForm = this.fb.group({
      email: ['', Validators.required]
    })
  }

  public onSubmit(form: FormGroup): void {
    if (this.recoveryForm.valid) {
      this.isSubmitted = true
      this.onRecoverPassword()
    }
  }

  private dispatchDialog() {
    this.openDialog({
      template: this.recoveryDialogTemplate
    })
  }

  private openDialog(dialogData: DialogData): void {
    this.dialog = this.dialogFactoryService.open(dialogData)
    this.closeDialogAction()
  }

  private closeDialogAction(): void {
    this.dialog.close().subscribe(data => {
      this.closing.emit(true)
    })
  }

  onRecoverPassword() {
    let email = this.recoveryForm.get('email')?.value
    this.userService.recoverPassword(email).subscribe({
      next: async response => {
        let snackBarRef = this.snackBar.open('email sended')
        this.login.emit(true)
        this.dialog.closeDialog()
      },
      complete: () => {},
      error: error => {}
    })
    this.dialog.closeDialog()
  }

  onLoginUser() {
    this.login.emit(true)
    this.dialog.closeDialog()
  }
}
