import { Overlay, OverlayModule } from '@angular/cdk/overlay'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { PopoverDirective } from '../../directives/popover.directive'
import { PopoverContainerComponent } from './popover-container/popover-container.component'
import { PopoverService } from './popover.service'

@NgModule({
  imports: [CommonModule, OverlayModule],
  declarations: [PopoverDirective, PopoverContainerComponent],
  exports: [PopoverDirective, PopoverContainerComponent],
  providers: [Overlay, PopoverService]
})
export class PopoverModule {}
