import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { DialogData } from '../../models/dialog-data.model'
import { DialogOptions } from '../../models/dialog-options.model'
import { DialogComponent } from './dialog.component'
import { DialogService } from './dialog.service'

@Injectable({
  providedIn: 'root'
})
export class DialogFactoryService<T = undefined> {
  constructor(private dialog: MatDialog) {}

  public open(
    dialogData: DialogData<T>,
    options: DialogOptions = {
      width: 600,
      disableClose: true,
      panelClass: 'custom-dialog'
    }
  ): DialogService<T> {
    const dialogRef = this.dialog.open<DialogComponent<T>, DialogData<T>>(DialogComponent, {
      ...this.fetchOptions(options),
      data: dialogData
    })

    return new DialogService(dialogRef)
  }

  private fetchOptions({
    width,
    disableClose,
    panelClass
  }: DialogOptions): Pick<MatDialogConfig<DialogData<T>>, 'width' | 'disableClose' | 'panelClass'> {
    return {
      width: `${width}px`,
      disableClose,
      panelClass
    }
  }
}
