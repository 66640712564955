import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  constructor(private _http: HttpClient, private snackBar: MatSnackBar, private router: Router) {}

  public async getAllCategories(): Promise<Observable<any>> {
    return this._http.get(environment.apiUrl + '/categories')
  }

  public async getCategory(id: string): Promise<Observable<any>> {
    return this._http.get(environment.apiUrl + '/categories/' + id)
  }
}
