import { IMonthsFormatter } from '../models/dates.model'

export const MONTHS_FORMATTER: IMonthsFormatter = {
  '1': {
    REDUCED: 'ENE'
  },
  '2': {
    REDUCED: 'FEB'
  },
  '3': {
    REDUCED: 'MAR'
  },
  '4': {
    REDUCED: 'ABR'
  },
  '5': {
    REDUCED: 'MAY'
  },
  '6': {
    REDUCED: 'JUN'
  },
  '7': {
    REDUCED: 'JUL'
  },
  '8': {
    REDUCED: 'AGO'
  },
  '9': {
    REDUCED: 'SEP'
  },
  '10': {
    REDUCED: 'OCT'
  },
  '11': {
    REDUCED: 'NOV'
  },
  '12': {
    REDUCED: 'DEC'
  }
}
