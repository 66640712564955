<section class="payment-confirmation">
  <div class="payment-confirmation__container">
    <img class="payment-confirmation__logo" [src]="APP_IMAGES.LOGOV" />
    <div>
      <img src="../../../assets/icons/error-icon.png" />
    </div>
    <div style="margin-top: 32px">
      <mat-label> Error en tu compra </mat-label>
    </div>
    <div style="max-width: 500px; margin-top: 32px">
      <mat-label style="font-weight: 100">
        Lo sentimos se ha producido un error al efectuar el pago, por favor vuelve a
        intentarlo.</mat-label
      >
    </div>
    <div class="payment-confirmation__events">
      <h4>Eventos Similares</h4>

      <app-swiper
        [carouselConfig]="carouselConfig"
        [eventsData]="featuredEvents"
        [lightMode]="true"></app-swiper>
    </div>
    <mat-divider class="divider dark"></mat-divider>
    <app-footer></app-footer>
  </div>
</section>
