<section class="about-us">
  <div class="about-us__container">
    <div class="about-us__title">
      <h1 class="about-us__title--main">{{ 'TITLES.COOKIES_POLICY' | translate }}</h1>
    </div>
    <div class="main__text">
      <p>
        Las cookies son unos pequeños ficheros de texto que se instalan en el disco duro de tus
        dispositivos a través de tu navegador cuando utilizas nuestros sitios web, sea la página web
        como tal o la app móvil, y nos sirven para recoger cierta información sobre el uso de
        nuestro sitio web.
      </p>
      <p>
        Al acceder a nuestra web o app de venta de entradas, al adquirir entradas a través de ellas
        o crear una cuenta de usuario, estás aceptando que tanto nosotros como otras organizaciones
        de terceros utilicemos cookies, siempre de conformidad con su configuración de cookies.
      </p>
      <p>
        Existen diferentes tipos de cookies en función de su duración en tu equipo. Las cookies
        permanentes permanecerán en este mientras que dure su periodo de vigencia o hasta que sean
        eliminadas, mientras que las cookies de sesión desaparecerán una vez que abandones el sitio
        web o app.
      </p>
      <p>
        Las cookies que Ticketernal instala en su dispositivo pueden ser cookies propias, las que
        Ticketernal instala en tu dispositivo, o de terceros, las que son controladas por un tercero
        pero que se instalan en tu ordenador cuando utilizas nuestra web.
      </p>
      <p>
        Estas cookies nos ayudan a mejorar Ticketernal y a que el servicio que prestamos a nuestros
        usuarios sea cada vez mejor y más personalizado, acelerando tus búsquedas, reconociéndose
        cada vez que ingreses en nuestra plataforma y guardando tus preferencias y gustos para
        adaptarnos a tus intereses
      </p>
      <h3>¿Qué tipo de cookies existen?</h3>
      <p>
        Existen cinco tipos de cookies en función de su aplicación. Las primeras e imprescindibles
        son las denominadas <strong>Cookies Técnicas</strong>, ya que sin ellas nuestro sitio web y
        app no funcionaría. Estas cookies te permitirán la navegación y la utilización de los
        diferentes opciones o servicios, como son el registro del usuario, el acceso a apartados en
        los que es obligatorio encontrarse registrado, recordar y almacenar las entradas adquiridas,
        etc.
      </p>
      <p>
        El segundo tipo de cookies son las <strong>Cookies de Personalización</strong>, las cuales
        permiten a los usuarios el acceso a la web o app con algunas características generales
        predefinidas en función de los criterios establecidos en el equipo de dicho usuario. Algunos
        ejemplos son el nombre de usuario, el idioma, etc.
      </p>
      <p>
        Las <strong>Cookies Analíticas</strong> son aquellas que permiten a los responsables de la
        web o app el análisis de los diferentes comportamientos llevados a cabo por los usuarios con
        la finalidad de conocer el funcionamiento de la web o app, adaptarse a los gustos de los
        usuarios y mejorar el aplicativo. Sirven para conocer la actividad de los usuarios frente a
        plataforma, que secciones visitan más dentro de esta y que se debe cambiar para mejorar,
        entre otros.
      </p>
      <p>
        Por último, encontramos las <strong>Cookies Publicitarias</strong>. Estas sirven para
        gestionar los espacios publicitarios de la web y app de una forma eficaz y remitir mensajes
        de publicidad y marketing personalizados, en función de las páginas que hayas visitado o
        hayas mostrado interés de forma previa y de tus compras. En función de esto variará los
        diferentes criterios como son la frecuencia con la que se muestran los anuncios o el tipo de
        contenido.
      </p>
      <h3>¿Cómo puedo desactivar las cookies?</h3>
      <p>
        Lo primero que debes saber es que el bloqueo o desactivación de todas las cookies puede
        limitar la experiencia en nuestra web o app, limitar sus funcionalidades o, incluso, impedir
        su correcto funcionamiento y la utilización de algunos servicios, aunque dicho bloqueo o
        desactivación de todas las cookies ayude a proteger su privacidad.
      </p>
      <p>
        Para desactivar las cookies en los principales navegadores web, acceda a los siguientes
        tutoriales:
      </p>
      <ul>
        <li>
          Google Chrome:
          <a href="http://support.google.com/chrome/answer/95647?hl=es"
            >http://support.google.com/chrome/answer/95647?hl=es</a
          >
        </li>
        <li>
          Mozilla Firefox:
          <a
            href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we"
            >http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</a
          >
        </li>
        <li>
          Internet Explorer:
          <a
            href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we"
            >http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</a
          >
        </li>
        <li>
          Safari:
          <a href="https://support.apple.com/es-es/HT201265"
            >https://support.apple.com/es-es/HT201265</a
          >
        </li>
        <li>
          Google Chrome para Android:
          <a
            href="https://support.google.com/chrome/answer/114662?hl=es&visit_id=638079887822779107-2021176953&rd=1"
            >https://support.google.com/chrome/answer/114662?hl=es&visit_id=638079887822779107-2021176953&rd=1</a
          >
        </li>
        <li>
          Opera:
          <a href="https://help.opera.com/en/latest/web-preferences/"
            >https://help.opera.com/en/latest/web-preferences/</a
          >
        </li>
      </ul>
      <p>
        Si no desea permitir que Ticketernal y otras organizaciones de terceros utilizamos cookies
        en los correos electrónicos que le enviamos, la mejor forma es ver el texto sin formato de
        estos, es decir, no permitir imágenes cuando los vea. Algunos buscadores permiten bloquear o
        desactivar estas cookies.
      </p>
      <p>
        En caso de tener alguna duda adicional sobre nuestra política de cookies, puede ponerse en
        contacto con nosotros a través de
        <a href="mailto:lopd@ticketernal.com">lopd@ticketernal.com</a> y le responderemos lo antes
        posible.
      </p>
      <h3>Actualizaciones de la presente política</h3>
      <p>
        La última actualización realizada a nuestra política de cookies es de fecha [01/2023]. Esta
        podrá ser actualizada en cualquier momento sin previo aviso en ninguno de nuestros canales,
        por lo que recomendamos que la visite periódicamente para comprobar si hubiese sufrido algún
        tipo de modificación.
      </p>
    </div>
    <app-footer></app-footer>
  </div>
</section>
