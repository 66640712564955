import { TemplateRef } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { Observable } from 'rxjs'
import { first } from 'rxjs/operators'
import { DialogComponent } from './dialog.component'

type DialogRef<T> = MatDialogRef<DialogComponent<T>>

export class DialogService<T = undefined> {
  opened$ = this.dialogRef.afterOpened().pipe(first())

  constructor(private dialogRef: DialogRef<T>) {}

  get context(): T {
    return this.dialogRef.componentInstance.data.context
  }

  public close(): Observable<any> {
    return this.dialogRef.afterClosed()
  }

  public closeDialog(): void {
    this.dialogRef.close()
  }

  public setTemplate(template: TemplateRef<any>): void {
    this.dialogRef.componentInstance.data.template = template
  }
}
