import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { APP_IMAGES } from '../../const/images.const'
import { APP_ROUTES } from '../../const/routes.const'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  constructor(private router: Router) {}

  public readonly APP_IMAGES = APP_IMAGES

  public goAboutUs(): void {
    this.router.navigate([APP_ROUTES.ABOUT_US])
  }
  public goAdvertising(): void {
    this.router.navigate([APP_ROUTES.ADVERTISING])
  }

  public goToSellTickets(): void {
    this.router.navigate([APP_ROUTES.SELL_WITH_US])
  }

  public goToUseTerms(): void {
    this.router.navigate([APP_ROUTES.USE_TERMS])
  }

  public goToPrivacyPolicy(): void {
    this.router.navigate([APP_ROUTES.PRIVACY_POLICY])
  }

  public goLegal(): void {
    this.router.navigate([APP_ROUTES.LEGAL])
  }
}
