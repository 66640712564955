import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { SharedModule } from '../../shared/shared.module'
import { CookiesRoutingModule } from './cookies-routing.module'
import { CookiesComponent } from './cookies/cookies.component'

@NgModule({
  declarations: [CookiesComponent],
  imports: [CommonModule, CookiesRoutingModule, SharedModule]
})
export class CookiesModule {}
