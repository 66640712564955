import { registerLocaleData } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http'
import es from '@angular/common/locales/es'
import { LOCALE_ID, NgModule } from '@angular/core'
import { GoogleMapsModule } from '@angular/google-maps'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatDividerModule } from '@angular/material/divider'
import { MatIconModule } from '@angular/material/icon'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatSliderModule } from '@angular/material/slider'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatTooltipModule } from '@angular/material/tooltip'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ServiceWorkerModule } from '@angular/service-worker'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { environment } from '../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { CookiesModule } from './modules/cookies/cookies.module'
import { PaymentConfirmationErrorRoutingModule } from './modules/payment-confirmation-error/payment-confirmation-error/payment-confirmation-error-routing.module'
import { SvgInitDirective } from './shared/directives/svg-init.directive'
import { SvgDirective } from './shared/directives/svg.directive'
import { ExpiredSessionInterceptor } from './shared/interceptors/expiredSession'
import { SharedModule } from './shared/shared.module'

registerLocaleData(es)

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http)
}

@NgModule({
  declarations: [AppComponent, SvgDirective, SvgInitDirective],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),

    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    SharedModule,
    HttpClientModule,
    MatSnackBarModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatDividerModule,
    MatSidenavModule,
    MatCardModule,
    MatTooltipModule,
    FontAwesomeModule,
    MatMenuModule,
    MatButtonModule,
    MatSliderModule,
    MatProgressBarModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    CookiesModule,
    GoogleMapsModule,
    PaymentConfirmationErrorRoutingModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'es-*',
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: ExpiredSessionInterceptor, multi:true 
    }

  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
