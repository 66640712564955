import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core'

import { TemplatePortal } from '@angular/cdk/portal'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { PopoverService } from '../components/popover/popover.service'

@Directive({
  selector: '[appPopoverTrigger]'
})
export class PopoverDirective implements OnDestroy, OnInit {
  @Input()
  appPopoverTrigger!: TemplateRef<object>

  @Input()
  closeOnClickOutside: boolean = false

  private unsubscribe = new Subject()
  private overlayRef!: OverlayRef

  constructor(
    private elementRef: ElementRef,
    private overlay: Overlay,
    private vcr: ViewContainerRef,
    private popoverService: PopoverService
  ) {}

  ngOnInit(): void {
    this.createOverlay()
    this.popoverService.getState().subscribe(resp => {
      if (resp) {
        this.detachOverlay()
      }
    })
  }

  ngOnDestroy(): void {
    this.detachOverlay()
    this.unsubscribe.unsubscribe()
  }

  @HostListener('click') clickou() {
    this.attachOverlay()
  }

  private createOverlay(): void {
    const scrollStrategy = this.overlay.scrollStrategies.block()
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top'
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom'
        }
      ])

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy,
      hasBackdrop: true,
      backdropClass: ''
    })

    this.overlayRef
      .backdropClick()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        if (this.closeOnClickOutside) {
          this.detachOverlay()
        }
      })
  }

  private attachOverlay(): void {
    if (!this.overlayRef.hasAttached()) {
      const periodSelectorPortal = new TemplatePortal(this.appPopoverTrigger, this.vcr)

      this.overlayRef.attach(periodSelectorPortal)
    }
  }

  private detachOverlay(): void {
    if (this.overlayRef.hasAttached()) {
      this.overlayRef.detach()
    }
  }
}
