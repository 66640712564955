import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {
  constructor(private http: HttpClient) {}

  public verifyAddress(address: string): any {
    /*const res = {
      status: true,
      lng: 0,
      lat: 0
    }

    return res*/
    const url =
      'https://maps.googleapis.com/maps/api/geocode/json?address=' +
      address +
      environment.googleApiKey
    let locc
    this.http.post(url, locc).subscribe((res: any) => {
      //const body = JSON.parse(res._body)
      if (
        res.status === 'ZERO RESULTS' ||
        res.results['0'].geometry.location_type === 'APROXIMATE'
      ) {
        return res
      } else {
        const location = res.results['0'].geometry.location
        res.lng = location.lng
        res.lat = location.lat
        res.status = true
        return res
      }
    })
  }

  public getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        resp => {
          resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude })
        },
        err => {
          reject(err)
        }
      )
    })
  }

  public async getReverseGeocoding(lat: number, lng: number): Promise<any> {
    const url =
      'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
      lat +
      ',' +
      lng +
      '&key=' +
      environment.googleApiKey
    return this.http.get(url)
  }
}

/*

const url = 'https://maps.googleapis.com/maps/api/geocode/json?address='+
            this.inputStreet+", "+this.inputCity+", "+this.inputProv+
            environment.googleApiKey;
*/
