import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { firstValueFrom } from 'rxjs'
import { PopoverService } from 'src/app/shared/components/popover/popover.service'
import { APP_IMAGES } from 'src/app/shared/const/images.const'
import { APP_ROUTES } from 'src/app/shared/const/routes.const'
import { EventService } from 'src/app/shared/services/event.service'
import { FilterService } from 'src/app/shared/services/filter.service'
import { GeolocationService } from 'src/app/shared/services/geolocation.service'

@Component({
  selector: 'app-popover-location',
  templateUrl: './popover-location.component.html',
  styleUrls: ['./popover-location.component.scss']
})
export class PopoverLocationComponent {
  public origen = 'popover'
  private isOpened = false
  latitude: any
  longitude: any
  events: any
  public citiesList: Array<any> = [
    {
      id: '0',
      title: 'Madrid'
    },
    {
      id: '1',
      title: 'Lleida'
    },
    {
      id: '2',
      title: 'Palencia'
    },
    {
      id: '3',
      title: 'Toledo'
    },
    {
      id: '4',
      title: 'Valencia'
    }
  ]

  constructor(
    private popoverService: PopoverService,
    public geolocationService: GeolocationService,
    private eventsService: EventService,
    private router: Router,
    public filterService: FilterService
  ) {}

  public closePopover() {
    if (this.isOpened) this.popoverService.setState(true)
  }
  public async showAllEvents() {
    this.events = []
    await firstValueFrom(await this.eventsService.getAllEvents()).then(async (res: any) => {
      for (let e of res.event) {
        let picture = APP_IMAGES.LOGO
        if (e.picture !== null) {
          picture = e.picture.url
        }
        const evento = {
          id: e.id,
          imageUrl: picture,
          headerUrl: picture,
          title: e.name,
          dateText: new Date(e.initDate).toLocaleString('es-ES', {
            year: 'numeric',
            day: 'numeric',
            month: 'short',
            weekday: 'short'
          }),
          date: new Date(e.initDate).toLocaleDateString('es-ES', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
          }),
          time: e.initTime,
          place: e.enclosure.name,
          city: e.enclosure.address.city,
          address: e.enclosure.address,
          priceFrom: 10,
          tags: e.tags,
          description: e.description,
          sessions: e.sessions
        }
        this.events.push(evento)
      }

      this.filterService.setCity('Espana')
      await this.router.navigate([APP_ROUTES.FILTER], {
        state: { loadfilter: this.events }
      })
    })
  }

  public async getLocation() {
    this.events = []
    this.geolocationService.getPosition().then(async pos => {
      this.latitude = pos.lat
      this.longitude = pos.lng
      //await this.geolocationInversed()
      const location = `${this.latitude},${this.longitude}`
      await firstValueFrom(await this.eventsService.getEventsByLocation(location)).then(
        async (res: any) => {
          for (let e of res.event) {
            let picture = APP_IMAGES.LOGO
            if (e.picture !== null) {
              picture = e.picture.url
            }
            const evento = {
              id: e.id,
              imageUrl: picture,
              headerUrl: picture,
              title: e.name,
              dateText: new Date(e.initDate).toLocaleString('es-ES', {
                year: 'numeric',
                day: 'numeric',
                month: 'short',
                weekday: 'short'
              }),
              date: new Date(e.initDate).toLocaleDateString('es-ES', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              }),
              time: e.initTime,
              place: e.enclosure.name,
              city: e.enclosure.address.city,
              address: e.enclosure.address,
              priceFrom: 10,
              tags: e.tags,
              description: e.description,
              sessions: e.sessions
            }
            this.events.push(evento)
          }

          await this.router.navigate([APP_ROUTES.FILTER], {
            state: { loadfilter: this.events }
          })
        }
      )
    })
  }
}
