import { Component, Input } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ICategory } from 'src/app/shared/models/categories.model'

@Component({
  selector: 'app-category-card',
  templateUrl: './category-card.component.html',
  styleUrls: ['./category-card.component.scss']
})
export class CategoryCardComponent {
  @Input() data: ICategory

  constructor(public translate: TranslateService) {}
}
