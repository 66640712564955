import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'

@Injectable()
export class PopoverService {
  private _stateSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  constructor() {}

  get state(): boolean {
    return this._stateSubject.getValue()
  }

  getState(): Observable<boolean> {
    return this._stateSubject.asObservable()
  }

  setState(value: boolean) {
    return this._stateSubject.next(value)
  }
}
