import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Router } from '@angular/router'
import { Observable, lastValueFrom, map, startWith } from 'rxjs'
import { APP_IMAGES } from 'src/app/shared/const/images.const'
import { APP_ROUTES } from 'src/app/shared/const/routes.const'
import { EventService } from 'src/app/shared/services/event.service'
import { FilterService } from '../../services/filter.service'

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {
  @Input() searchData: Array<any>
  @Input() calendarBox: boolean
  @Input() placeholder: string
  @Input() origen: string = ''

  @Output() closePopover = new EventEmitter<boolean>()
  @Output() citySelected = new EventEmitter<any>()

  public filteredSearch: Observable<Array<any>>
  public filteredSearchCity: Observable<Array<any>>
  public city = ''
  public eventsFiltered: any[] = []
  public eventsFiltered2: any[] = []
  public searchCtrl = new FormControl()
  public readonly APP_IMAGES = APP_IMAGES
  public userImage: string
  public isLogged: boolean = true
  private searchList: Array<any> = []
  public showNoEventsMessage = false
  constructor(
    private router: Router,
    public eventService: EventService,
    public filterService: FilterService,
    private snackBar: MatSnackBar
  ) {}

  public ngOnInit() {
    if (this.origen === 'popover') {
      this.getEvents()
    } else {
      this.searchList = this.searchData

      this.filteredSearch = this.searchCtrl.valueChanges.pipe(
        startWith(''),
        map(search => {
          if (search) {
            this.filterSearch(search)
          }
          return this.searchList.slice()
        })
      )
    }
  }
  public onInput(event: Event) {
    const value = (event.currentTarget as HTMLInputElement).value
    this.city = value
    this.citySelected.emit(this.city)
  }

  private async getEvents() {
    let picture = APP_IMAGES.LOGO
    lastValueFrom(await this.eventService.getAllEvents()).then(res => {
      for (const evento of res.event) {
        if (evento.picture !== null) {
          picture = evento.picture.url
        }
        const event2Show = {
          id: evento.id,
          imageUrl: picture,
          headerUrl: picture,
          title: evento.name,
          dateText: new Date(evento.initDate).toLocaleString('es-ES', {
            year: 'numeric',
            day: 'numeric',
            month: 'short',
            weekday: 'short'
          }),
          date: new Date(evento.initDate).toLocaleDateString('es-ES', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
          }),
          time: evento.initTime,
          place: evento.enclosure.name,
          city: evento.enclosure.address.city,
          address: evento.enclosure.address,
          priceFrom: 10,
          tags: evento.tags,
          description: evento.description,
          sessions: evento.sessions
        }
        this.eventsFiltered.push(event2Show)
      }
    })
  }
  private filterSearchCity(value: string): void {
    const normalizeValue = this.normalizeText(value)

    const result = this.eventsFiltered.filter(search => {
      const normalizedCity = this.normalizeText(search.city)
      return normalizedCity.includes(normalizeValue)
    })

    this.eventsFiltered2 = result
    this.showNoEventsMessage = this.eventsFiltered2.length === 0

    if (this.showNoEventsMessage) {
      this.snackBar.open('No se han encontrado eventos para esta ciudad', 'Cerrar', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      })
    }
  }

  private filterSearchLocation(value: string): void {
    const filterValue = value.toLowerCase()
    const result = this.eventsFiltered.filter(search =>
      search.adress.toLowerCase().includes(filterValue)
    )
    this.eventsFiltered2 = result
  }

  private filterSearch(value: string): void {
    const normalizeValue = this.normalizeText(value)

    const result = this.searchData.filter(search => {
      const normalizedTitle = this.normalizeText(search.title)
      return normalizedTitle.includes(normalizeValue)
    })

    this.searchList = result
  }

  private normalizeText(text: string): string {
    return text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
  }

  public goToHelpPage(): void {
    this.router.navigate([APP_ROUTES.HELP])
  }

  public goToLoginPage(): void {
    // this.router.navigate([APP_ROUTES.LOGIN])
  }

  public goToHome(): void {
    this.searchCtrl.setValue('')
    this.filterSearch('')
    //  this.router.navigate([APP_ROUTES.HOME])
  }

  public close(): void {
    this.closePopover.emit(true)
  }

  public goToEvent(eventoId: number) {
    this.filterSearch('')
    this.reloadCurrentRoute()
    this.router.navigate([APP_ROUTES.EVENT_DETAIL, eventoId])
  }
  reloadCurrentRoute() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false
    this.router.onSameUrlNavigation = 'reload'
    this.router.navigate([this.router.url])
  }
  public async Enter(event: KeyboardEvent): Promise<void> {
    if (event.key === 'Enter') {
      if (this.origen === 'popover') {
        this.filterSearchCity(this.city)
        this.filterService.setCity(this.eventsFiltered2[0].city)
        this.router.navigate([APP_ROUTES.FILTER], {
          state: { loadfilter: this.eventsFiltered2 }
        })
      } else {
        this.router.navigate([APP_ROUTES.FILTER], {
          state: { loadfilter: this.searchList }
        })
      }
    }
  }

  goToAllEventsSearched() {
    this.router.navigate([APP_ROUTES.FILTER], {
      state: { loadfilter: this.searchList }
    })
  }
}
