import { Component, Input, OnInit } from '@angular/core'
import { MatMenuModule } from '@angular/material/menu'
import { MatTreeModule } from '@angular/material/tree'
import { Router } from '@angular/router'
import { faBars, faMagnifyingGlass, faUser } from '@fortawesome/free-solid-svg-icons'
import { lastValueFrom } from 'rxjs'
import { APP_IMAGES } from 'src/app/shared/const/images.const'
import { APP_ROUTES } from 'src/app/shared/const/routes.const'
import { IEvent } from 'src/app/shared/models/events.model'
import { EventService } from 'src/app/shared/services/event.service'
import { UserService } from 'src/app/shared/services/user.service'
import { CategoriesService } from '../../services/categories.service'
import { SubCategoriesService } from './../../services/subCategories.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() user: any
  showSubcategories = false
  bars = faBars
  mGlass = faMagnifyingGlass
  iconUser = faUser
  public readonly APP_IMAGES = APP_IMAGES
  public userImage: string
  public avatar: string
  public isLogged: boolean = false
  public isOpenLoginDialog: boolean = false
  public isOpenRegisterDialog: boolean = false
  public isOpenRecoverDialog: boolean = false
  public isOpenMobileSearchDialog: boolean = false
  public isOpenMobileMenuDialog: boolean = false
  //public featuredEvents: Array<IEvent> = FAKE_EVENTS
  public featuredEvents: Array<IEvent> = []
  public subList: any = ''
  public openSubc: boolean = false
  @Input() eventsFiltered: any[] = []
  public categories: any = []
  public subCategories: any = []

  //subcategorías temporales
  public musicSub: Array<any> = [
    {
      id: 0,
      name: 'Pop Rock'
    },
    {
      id: 1,
      name: 'Urban Latino'
    },
    {
      id: 2,
      name: 'Dance Electrónica'
    },
    {
      id: 3,
      name: 'Indie Alternativo'
    },
    {
      id: 4,
      name: 'Clásica'
    },
    {
      id: 5,
      name: 'Jazz Folk'
    },
    {
      id: 6,
      name: 'Otros'
    },
    {
      id: 7,
      name: 'Todos Música'
    }
  ]

  public scenicsSub: Array<any> = [
    {
      id: 0,
      name: 'Teatro'
    },
    {
      id: 1,
      name: 'Ballet Danza'
    },
    {
      id: 2,
      name: 'Ópera Zarzuela'
    },
    {
      id: 3,
      name: 'Magia'
    },
    {
      id: 4,
      name: 'Musicales'
    },
    {
      id: 5,
      name: 'Humor'
    },
    {
      id: 6,
      name: 'Todos Artes Escénicas'
    }
  ]

  public festivalSub: Array<any> = [
    {
      id: 0,
      name: 'Todos Festivales'
    }
  ]

  public sportSub: Array<any> = [
    {
      id: 0,
      name: 'Fútbol'
    },
    {
      id: 1,
      name: 'Baloncesto'
    },
    {
      id: 2,
      name: 'Tenis'
    },
    {
      id: 3,
      name: 'Motor'
    },
    {
      id: 4,
      name: 'Otros'
    },
    {
      id: 5,
      name: 'Todos Deportes'
    }
  ]

  public familySub: Array<any> = [
    {
      id: 0,
      name: 'Magia'
    },
    {
      id: 1,
      name: 'Circo'
    },
    {
      id: 2,
      name: 'Espectáculos infantiles'
    },
    {
      id: 3,
      name: 'Actividades Talleres'
    },
    {
      id: 4,
      name: 'Parques temáticos'
    },
    {
      id: 5,
      name: 'Todos Familiares'
    }
  ]

  public cultureSub: Array<any> = [
    {
      id: 0,
      name: 'Museos Exposiciones'
    },
    {
      id: 1,
      name: 'Ferias Congresos'
    },
    {
      id: 2,
      name: 'Conferencias Presentaciones'
    },
    {
      id: 3,
      name: 'Tours Visitas'
    },
    {
      id: 4,
      name: 'Eventos Fiestas'
    },
    {
      id: 5,
      name: 'Otros'
    },
    {
      id: 6,
      name: 'Todos Cultura y más'
    }
  ]
  filterService: any

  constructor(
    private eventsService: EventService,
    private router: Router,
    public menu: MatMenuModule,
    public tree: MatTreeModule,
    private userService: UserService,
    private categoriesService: CategoriesService,
    private subCategoriesService: SubCategoriesService
  ) {
    //detects login expire session
    window.addEventListener('storage', event => {
      //if (event.storageArea === localStorage) {
      //if (Object.keys(localStorage).length === 0) {
      if (localStorage.getItem('fnds::user') == null) {
        this.logOut()
        this.openLoginDialog()
      }
    })
  }

  public async ngOnInit() {
    this.subList = this.musicSub
    this.userService.getObservableUser().subscribe((data: boolean) => {
      this.isLogged = data
    })

    this.isLogged = this.userService.isUserAuthenticated()

    try {
      let userAvatarJson = await this.userService.getlocalStorageuser()
      let userAvatar = JSON.parse(userAvatarJson)
      if (userAvatar) {
        if (userAvatar.user.avatar) {
          if (userAvatar.user.avatar.url !== null) {
            this.userImage = userAvatar.user.avatar.url
          }
        }
      }
    } catch (error) {
      console.error('Error al obtener el valor del almacenamiento local:', error)
    }

    await this.getCategories()
    await this.getEvents()
  }

  public goToHelpPage(): void {
    this.router.navigate([APP_ROUTES.HELP])
  }

  public goToHomePage() {
    this.router.navigate([APP_ROUTES.HOME])
  }

  public openLoginDialog(): void {
    this.isOpenLoginDialog = true
  }

  public isLoginDialogClosed(event: boolean): void {
    this.isOpenLoginDialog = false
  }

  public isRegisterDialogClosed(event: boolean): void {
    this.isOpenRegisterDialog = false
  }

  public isRecoverDialogClosed(event: boolean): void {
    this.isOpenRecoverDialog = false
  }

  public openRegisterDialog(): void {
    this.isOpenRegisterDialog = true
  }

  public openRecoverDialog(): void {
    this.isOpenRecoverDialog = true
  }

  public openMobileSearchDialog(): void {
    this.isOpenMobileSearchDialog = true
  }

  public isMobileSearchDialogClosed(event: boolean): void {
    this.isOpenMobileSearchDialog = false
  }
  public openMobileMenuDialog(): void {
    this.isOpenMobileMenuDialog = true
  }

  public isMobileMenuDialogClosed(event: boolean): void {
    this.isOpenMobileMenuDialog = false
  }

  public openMusicSubc(): void {
    let difference: any
    if (this.openSubc) {
      difference = [
        ...this.getDifference(this.musicSub, this.subList),
        ...this.getDifference(this.subList, this.musicSub)
      ]
      if (difference?.length === 0) {
        this.openSubc = !this.openSubc
      } else {
        this.subList = this.musicSub
      }
    } else {
      this.openSubc = !this.openSubc
      this.subList = this.musicSub
    }
  }

  public openScenicArtsSubc(): void {
    let difference: any
    if (this.openSubc) {
      difference = [
        ...this.getDifference(this.scenicsSub, this.subList),
        ...this.getDifference(this.subList, this.scenicsSub)
      ]
      if (difference?.length === 0) {
        this.openSubc = !this.openSubc
      } else {
        this.subList = this.scenicsSub
      }
    } else {
      this.openSubc = !this.openSubc
      this.subList = this.scenicsSub
    }
  }

  public openFestivalsSubc(): void {
    let difference: any
    if (this.openSubc) {
      difference = [
        ...this.getDifference(this.festivalSub, this.subList),
        ...this.getDifference(this.subList, this.festivalSub)
      ]
      if (difference?.length === 0) {
        this.openSubc = !this.openSubc
      } else {
        this.subList = this.festivalSub
      }
    } else {
      this.openSubc = !this.openSubc
      this.subList = this.festivalSub
    }
  }

  public openSportsSubc(): void {
    let difference: any
    if (this.openSubc) {
      difference = [
        ...this.getDifference(this.sportSub, this.subList),
        ...this.getDifference(this.subList, this.sportSub)
      ]
      if (difference?.length === 0) {
        this.openSubc = !this.openSubc
      } else {
        this.subList = this.sportSub
      }
    } else {
      this.openSubc = !this.openSubc
      this.subList = this.sportSub
    }
  }

  public openFamilySubc(): void {
    let difference: any
    if (this.openSubc) {
      difference = [
        ...this.getDifference(this.familySub, this.subList),
        ...this.getDifference(this.subList, this.familySub)
      ]
      if (difference?.length === 0) {
        this.openSubc = !this.openSubc
      } else {
        this.subList = this.familySub
      }
    } else {
      this.openSubc = !this.openSubc
      this.subList = this.familySub
    }
  }

  public openCultureSubc(): void {
    let difference: any
    if (this.openSubc) {
      difference = [
        ...this.getDifference(this.cultureSub, this.subList),
        ...this.getDifference(this.subList, this.cultureSub)
      ]
      if (difference?.length === 0) {
        this.openSubc = !this.openSubc
      } else {
        this.subList = this.cultureSub
      }
    } else {
      this.openSubc = !this.openSubc
      this.subList = this.cultureSub
    }
  }

  public getDifference(array1: any, array2: any) {
    return array1.filter((object1: { name: any }) => {
      return !array2.some((object2: { name: any }) => {
        return object1.name === object2.name
      })
    })
  }

  public logOut() {
    this.userService.logOut()
  }
  public goToTickets() {
    this.router.navigate([APP_ROUTES.SELL_TICKETS])
  }

  public goToProfile() {
    if (!this.isLogged) {
      this.openLoginDialog()
    }
    this.router.navigate([APP_ROUTES.PROFILE])
  }

  public async getUser() {
    this.user = await JSON.parse(localStorage.getItem('fnds::user') || '{}')
  }

  private async getEvents() {
    let picture = APP_IMAGES.LOGO
    lastValueFrom(await this.eventsService.getAllEvents()).then(async res => {
      for (const evento of res.event) {
        if (evento.picture !== null) {
          picture = evento.picture.url
        }
        const event2Show = {
          id: evento.id,
          imageUrl: picture,
          headerUrl: picture,
          title: evento.name,
          dateText: new Date(evento.initDate).toLocaleString('es-ES', {
            year: 'numeric',
            day: 'numeric',
            month: 'short',
            weekday: 'short'
          }),
          date: new Date(evento.initDate).toLocaleDateString('es-ES', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
          }),
          time: evento.initTime,
          place: evento.enclosure.name,
          city: evento.enclosure.address.city,
          address: evento.enclosure.address,
          priceFrom: 10,
          tags: evento.tags,
          description: evento.description,
          sessions: evento.sessions,
          organizator: evento.organizator,
          eventProgram: evento.eventProgram
        }
        this.featuredEvents.push(event2Show)
        await this.getUser()
      }
    })
  }
  public async getEventsSubByCategory(subCategory: any) {
    if (this.eventsFiltered.length != 0) {
      this.eventsFiltered = []
    }
    let picture = APP_IMAGES.LOGO
    lastValueFrom(await this.eventsService.getEventsBySubCategory2(subCategory)).then(res => {
      if (this.router.url == '/filter') {
        this.eventsFiltered = []
      }
      for (const evento of res.event) {
        if (evento.picture !== null) {
          picture = evento.picture.url
        }
        const event2Show = {
          id: evento.id,
          imageUrl: picture,
          headerUrl: picture,
          title: evento.name,
          dateText: new Date(evento.initDate).toLocaleString('es-ES', {
            year: 'numeric',
            day: 'numeric',
            month: 'short',
            weekday: 'short'
          }),
          date: evento.initDate,
          time: evento.initTime,
          place: evento.enclosure.name,
          city: evento.enclosure.address.city,
          address: evento.enclosure.address,
          priceFrom: 10,
          tags: evento.tags,
          description: evento.description,
          sessions: evento.sessions
        }
        this.eventsFiltered.push(event2Show)
      }
      this.goFilterPage()
    })
  }
  public goFilterPage(): void {
    this.router.navigate([APP_ROUTES.FILTER], {
      state: { loadfilter: this.eventsFiltered }
    })
  }

  private async getCategories() {
    this.categories = []
    lastValueFrom(await this.categoriesService.getAllCategories()).then(res => {
      for (const category of res.category) {
        const category2Show = {
          id: category.id,
          name: category.name,
          subcategories: category.subcategories
        }
        this.categories.push(category2Show)
      }
    })
  }

  private async getSubCategories() {
    this.subCategories = []
    lastValueFrom(await this.subCategoriesService.getAllSubCategories()).then(res => {
      for (const subcategory of res.subcategorie) {
        const subcategory2Show = {
          id: subcategory.id,
          name: subcategory.name,
          category: subcategory.category
        }
        this.subCategories.push(subcategory2Show)
      }
    })
  }

  public async openCategory(category: any) {
    this.showSubcategories = true
    let difference: any
    const subCategories = category.subcategories
    if (this.openSubc) {
      difference = [
        ...this.getDifference(subCategories, this.subList),
        ...this.getDifference(this.subList, subCategories)
      ]
      if (difference?.length === 0) {
        this.openSubc = !this.openSubc
      } else {
        this.subList = subCategories
      }
    } else {
      this.openSubc = !this.openSubc
      this.subList = subCategories
    }
  }

  closeCategory() {
    this.showSubcategories = false
  }
}
