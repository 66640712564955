import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core'
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { TranslateService } from '@ngx-translate/core'
import { firstValueFrom } from 'rxjs'
import { DialogFactoryService } from 'src/app/shared/components/dialog/dialog-factory.service'
import { DialogService } from 'src/app/shared/components/dialog/dialog.service'
import { APP_IMAGES } from 'src/app/shared/const/images.const'
import { DialogData } from 'src/app/shared/models/dialog-data.model'
import { UserService } from 'src/app/shared/services/user.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @Input()
  set open(value: boolean) {
    if (value) {
      this.dispatchDialog()
    }
  }
  @Output() closing = new EventEmitter<boolean>()
  @Output() registerUser = new EventEmitter<boolean>()
  @Output() recoverPassword = new EventEmitter<boolean>()

  @ViewChild('loginDialogTemplate')
  loginDialogTemplate: TemplateRef<any>

  get fc(): { [key: string]: AbstractControl } {
    return this.loginForm.controls
  }

  public readonly APP_IMAGES = APP_IMAGES
  public loginForm: FormGroup
  public isSubmitted: boolean = false

  private dialog: DialogService

  constructor(
    private dialogFactoryService: DialogFactoryService,
    public translate: TranslateService,
    private fb: FormBuilder,
    private userService: UserService,
    readonly snackBar: MatSnackBar
  ) {}

  public ngOnInit(): void {
    this.initForm()
  }

  private initForm(): void {
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    })
  }

  public async onSubmit(form: FormGroup) {
    if (this.loginForm.valid) {
      this.isSubmitted = true
      const user = {
        email: this.loginForm.get('email')?.value,
        password: this.loginForm.get('password')?.value
      }
      this.userService.login2(user).subscribe({
        next: async response => {
          this.userService.setToken(response.token)
          this.userService.setUser(response)
          let snackBarRef = this.snackBar.open('login')
          this.dialog.closeDialog()
          firstValueFrom(await this.userService.getLocalUser()).then(res => {
            localStorage.setItem('fnds::user', JSON.stringify(res.user))
            window.location.reload()
          })
        },
        complete: () => {},
        error: error => {
          const snackBarRef = this.snackBar.open('Datos incorrectos')
        }
      })
    }
  }

  private dispatchDialog() {
    this.openDialog({
      template: this.loginDialogTemplate
    })
  }

  private openDialog(dialogData: DialogData): void {
    this.dialog = this.dialogFactoryService.open(dialogData)
    this.closeDialogAction()
  }

  private closeDialogAction(): void {
    this.dialog.close().subscribe(data => {
      this.closing.emit(true)
    })
  }

  public onRegisterUser(): void {
    this.registerUser.emit(true)
    this.dialog.closeDialog()
  }

  public onRecoverPassword(): void {
    this.recoverPassword.emit(true)
    this.dialog.closeDialog()
  }
}
