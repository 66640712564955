<div class="search-container">
  <mat-form-field class="search-input">
    <mat-label>{{ placeholder }}</mat-label>
    <input
      (click)="goToHome()"
      (input)="onInput($event)"
      (keyup)="Enter($event)"
      matInput
      aria-label="Busqueda"
      [matAutocomplete]="auto"
      [formControl]="searchCtrl" />
    <mat-icon matPrefix class="search-input__icon">search</mat-icon>
    <mat-autocomplete #auto="matAutocomplete" [panelWidth]="500">
      <div *ngIf="calendarBox">
        <mat-label>Eventos</mat-label>
        <mat-option
          class="search-input__card"
          *ngFor="let searchObj of filteredSearch | async | slice : 0 : 5"
          [value]="searchObj.title"
          (onSelectionChange)="goToEvent(searchObj.id)">
          <app-calendar-box [date]="searchObj.date"></app-calendar-box>
          <div class="search-input__card--right">
            <span class="search-input__card--title">{{ searchObj.title }}</span>
            <div class="search-input__card--subtitle">
              <!--<small class="strong">
                {{ searchObj.priceFrom | currency : 'EUR' : 'symbol' : '1.0' }} -
              </small>-->
              <!-- <small>{{ searchObj.time | slice:0:-3 }} -</small>-->
              <small>{{ searchObj.place }}</small>
            </div>
          </div>
        </mat-option>
      </div>
      <div *ngIf="!calendarBox">
        <mat-option
          class="search-input__card small"
          *ngFor="let searchObj of filteredSearch | async"
          [value]="searchObj.title">
          <button mat-flat-button (click)="close()">
            <mat-icon class="popover-location__btn-icon">place</mat-icon> {{ searchObj.title }}
          </button>
        </mat-option>
      </div>
      <div class="search-input__footerMessage">
        <ng-container *ngIf="filteredSearch | async as searchArray">
          <mat-label *ngIf="searchArray.length > 5" (click)="goToAllEventsSearched()"
            >Ver todos los resultados</mat-label
          >
        </ng-container>
      </div>
    </mat-autocomplete>
  </mat-form-field>
</div>
