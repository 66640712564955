<ng-template #recoveryDialogTemplate>
  <div class="login-dialog">
    <img [src]="APP_IMAGES.LOGOV" class="login-dialog__logo" />

    <h3 class="login-dialog__title">{{ 'TITLES.RECOVER_PASSWORD' | translate }}</h3>

    <form class="login-dialog__form" [formGroup]="recoveryForm" (ngSubmit)="onSubmit(recoveryForm)">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'LABELS.EMAIL' | translate }}</mat-label>
        <input type="email" matInput formControlName="email" />
        <mat-error *ngIf="isSubmitted && fc['email'].errors && fc['email'].errors['required']">
          {{ 'ERRORS.EMAIL.REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>
      <button type="submit" mat-flat-button class="login-dialog__btn">
        {{ 'BUTTONS.RECOVER_PASSWORD' | translate }}
      </button>
    </form>

    <div class="login-dialog__links">
      <a mat-button class="register-dialog__btn">
        {{ 'LABELS.HAVE_ACCOUNT' | translate
        }}<span (click)="onLoginUser()">{{ 'TITLES.INIT_SESSION' | translate }}</span>
      </a>
    </div>
  </div>
</ng-template>
