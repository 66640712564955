import { Injectable } from '@angular/core'
import { MONTHS_FORMATTER } from './../const/dates.const'

@Injectable({
  providedIn: 'root'
})
export class DatesHelper {
  public getMonthReducedText(date: string): any {
    const dateWithoutZero = date.replace(/(^|\/)0+/g, '$1')
    const splitDate = dateWithoutZero.split('/')
    return MONTHS_FORMATTER[splitDate[1]].REDUCED
  }

  public getDay(date: string): string {
    const dateWithoutZero = date.replace(/(^|\/)0+/g, '$1')
    const splitDate = dateWithoutZero.split('/')
    return splitDate[0]
  }
}
