import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { lastValueFrom } from 'rxjs'
import { APP_IMAGES } from 'src/app/shared/const/images.const'
import { IEvent } from 'src/app/shared/models/events.model'
import { EventService } from 'src/app/shared/services/event.service'
import { SwiperOptions } from 'swiper'

@Component({
  selector: 'app-payment-confirmation',
  templateUrl: './payment-confirmation-error.component.html',
  styleUrls: ['./payment-confirmation-error.component.scss']
})
export class PaymentConfirmationErrorComponent {
  public readonly APP_IMAGES = APP_IMAGES


  public carouselConfig: SwiperOptions = {
    pagination: false,
    autoHeight: true,
    allowTouchMove: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: true
    },
    spaceBetween: 10,
    breakpoints: {
      1280: {
        slidesPerView: 3
      },
      600: {
        slidesPerView: 2
      },
      480: {
        slidesPerView: 1
      }
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    loop: true
  }
public featuredEvents: Array<IEvent> = []

  constructor(
    private router: Router,
    public translate: TranslateService,
    public eventService: EventService
  ) {
    this.getEvents()
  }
  private async getEvents() {
    let picture = APP_IMAGES.LOGO
    lastValueFrom(await this.eventService.getFeaturedEvents()).then(res => {
      for (const evento of res.event) {
        if (evento.picture !== null) {
          picture = evento.picture.url
        }
        const event2Show = {
          id: evento.id,
          imageUrl: picture,
          headerUrl: picture,
          title: evento.name,
          dateText: new Date(evento.initDate).toLocaleString('es-ES', {
            year: 'numeric',
            day: 'numeric',
            month: 'short',
            weekday: 'short'
          }),
          date: new Date(res.event.initDate).toLocaleDateString('es-ES', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
          }),
          time: evento.initTime,
          place: evento.enclosure.name,
          city: evento.enclosure.address.city,
          address: evento.enclosure.address,
          priceFrom: 10,
          tags: evento.tags,
          description: evento.description,
          sessions: evento.sessions,
          organizator: evento.organizator,
          eventProgram: evento.eventProgram
        }
        this.featuredEvents.push(event2Show)
      }
    })
  }
}

