export const APP_ROUTES = {
  HOME: 'home',
  EVENT_DETAIL: 'event-detail',
  VIEW_SEATMAP: 'view-seatmap',
  HELP: 'help',
  PROFILE: 'profile',
  RESET: 'reset-password',
  USE_TERMS: 'use-terms',
  PRIVACY_POLICY: 'privacy-policy',
  FILTER: 'filter',
  ABOUT_US: 'about-us',
  SELL_TICKETS: 'sell-tickets',
  BUY_TICKETS: 'buy-tickets',
  PAYMENT_CONFIRMATION: 'payment-confirmation',
  PAYMENT_CONFIRMATION_ERROR: 'payment-confirmation-error',
  COOKIES: 'cookies',
  LEGAL: 'legal',
  ADVERTISING: 'advertising',
  SELL_WITH_US: 'sell-with-us'
}
