import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { CookiesComponent } from './cookies/cookies.component'

const routes: Routes = [{ path: '', component: CookiesComponent }]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CookiesRoutingModule {}
