export const FAKE_EVENTS: Array<any> = [
  {
    id: '0',
    headerUrl: '/assets/img/examples/event-card-1.png',
    imageUrl: '/assets/img/examples/rigoberta.png',
    title: 'Fito & Fitipaldis',
    dateText: 'Sáb. 26 feb 19:30',
    date: '26/02/2022',
    time: '19:30',
    place: 'Wizink Center',
    city: 'Valencia',
    address: 'Carrer de Cavanilles, 1,  46010 València',
    priceFrom: 35,
    description: 'a description',
    tags: ['Conciertos', 'Conciertos musicales']
  },
  {
    id: '1',
    headerUrl: '/assets/img/examples/event-card-2.png',
    imageUrl: '/assets/img/examples/rigoberta.png',
    title: 'Aitana',
    dateText: 'Sáb. 26 feb 19:30',
    date: '26/02/2022',
    time: '19:30',
    place: 'Sala La Riviera',
    city: 'Valencia',
    address: 'Carrer de Cavanilles, 1,  46010 València',
    priceFrom: 25,
    description: 'a description',
    tags: ['Conciertos', 'Conciertos musicales']
  },
  {
    id: '2',
    headerUrl: '/assets/img/examples/event-card-3.png',
    imageUrl: '/assets/img/examples/rigoberta.png',
    title: 'The Weeknd',
    dateText: 'Sáb. 26 feb 19:30',
    date: '26/02/2022',
    time: '19:30',
    place: 'Wanda Metropolitano',
    city: 'Valencia',
    address: 'Carrer de Cavanilles, 1,  46010 València',
    priceFrom: 30,
    description: 'a description',
    tags: ['Conciertos', 'Conciertos musicales']
  },
  {
    id: '3',
    headerUrl: '/assets/img/examples/event-card-1.png',
    imageUrl: '/assets/img/examples/rigoberta.png',
    title: 'Fito & Fitipaldis',
    dateText: 'Sáb. 26 feb 19:30',
    date: '26/02/2022',
    time: '19:30',
    place: 'Wizink Center',
    city: 'Valencia',
    address: 'Carrer de Cavanilles, 1,  46010 València',
    priceFrom: 35,
    description: 'a description',
    tags: ['Conciertos', 'Conciertos musicales']
  },
  {
    id: '4',
    headerUrl: '/assets/img/examples/event-card-2.png',
    imageUrl: '/assets/img/examples/rigoberta.png',
    title: 'Aitana',
    dateText: 'Sáb. 26 feb 19:30',
    date: '26/02/2022',
    time: '19:30',
    place: 'Sala La Riviera',
    city: 'Valencia',
    address: 'Carrer de Cavanilles, 1,  46010 València',
    priceFrom: 25,
    description: 'a description',
    tags: ['Conciertos', 'Conciertos musicales']
  },
  {
    id: '5',
    headerUrl: '/assets/img/examples/event-card-3.png',
    imageUrl: '/assets/img/examples/rigoberta.png',
    title: 'The Weeknd',
    dateText: 'Sáb. 26 feb 19:30',
    date: '26/02/2022',
    time: '19:30',
    place: 'Wanda Metropolitano',
    city: 'Valencia',
    address: 'Carrer de Cavanilles, 1,  46010 València',
    priceFrom: 30,
    description: 'a description',
    tags: ['Conciertos', 'Conciertos musicales']
  }
]
