<ng-template #loginDialogTemplate>
  <div class="login-dialog">
    <img [src]="APP_IMAGES.LOGOV" class="login-dialog__logo" />

    <h3 class="login-dialog__title">{{ 'TITLES.INIT_SESSION' | translate }}</h3>

    <form class="login-dialog__form" [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm)">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'LABELS.EMAIL' | translate }}</mat-label>
        <input type="email" matInput formControlName="email" />
        <mat-error *ngIf="isSubmitted && fc['email'].errors && fc['email'].errors['required']">
          {{ 'ERRORS.EMAIL.REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'LABELS.PASSWORD' | translate }}</mat-label>
        <input type="password" matInput formControlName="password" />
        <mat-error
          *ngIf="isSubmitted && fc['password'].errors && fc['password'].errors['required']">
          {{ 'ERRORS.PASSWORD.REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>

      <button type="submit" mat-flat-button class="login-dialog__btn">
        {{ 'BUTTONS.INIT_SESSION' | translate }}
      </button>
    </form>

    <div class="login-dialog__links">
      <a mat-button class="login-dialog__link"
        ><span (click)="onRecoverPassword()">He olvidado mi contraseña</span></a
      >

      <a mat-button class="login-dialog__link">
        ¿Todavía no estás registrado?<span (click)="onRegisterUser()">Regístrate aquí</span>
      </a>
    </div>
  </div>
</ng-template>
