import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core'
import { Router } from '@angular/router'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { TranslateService } from '@ngx-translate/core'
import { lastValueFrom } from 'rxjs'
import { DialogFactoryService } from 'src/app/shared/components/dialog/dialog-factory.service'
import { DialogService } from 'src/app/shared/components/dialog/dialog.service'
import { APP_IMAGES } from 'src/app/shared/const/images.const'
import { APP_ROUTES } from 'src/app/shared/const/routes.const'
import { DialogData } from 'src/app/shared/models/dialog-data.model'
import { IEvent } from 'src/app/shared/models/events.model'
import { CategoriesService } from 'src/app/shared/services/categories.service'
import { EventService } from 'src/app/shared/services/event.service'
import { SubCategoriesService } from 'src/app/shared/services/subCategories.service'
import { FAKE_EVENTS } from 'src/fake-data/events-data'

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnInit {
  public categories: any = []
  public subCategories: any = []
  showSubcategories: boolean
  eventsFiltered: any = []
  categorySelected: any
  @Input()
  set open(value: boolean) {
    if (value) {
      this.dispatchDialog()
    }
  }
  @Output() closing = new EventEmitter<boolean>()
  @Output() login = new EventEmitter<boolean>()
  @ViewChild('mobileMenuDialogTemplate')
  mobileSearchDialogTemplate: TemplateRef<any>
  private dialog: DialogService
  public featuredEvents: Array<IEvent> = FAKE_EVENTS
  chevronLeft = faChevronLeft
  showSubSubMenu: boolean = false
  showSubmenu: boolean = false
  isMusicExpanded = true
  isExpanded = true
  showMusicSubmenu: boolean = false
  isMusicShowing = false
  isShowing = false
  isArtsExpanded = true
  showArtsSubmenu: boolean = false
  isArtsShowing = false
  isFestivalsExpanded = true
  showFestivalsSubmenu: boolean = false
  isFestivalsShowing = false
  isSportsExpanded = true
  showSportsSubmenu: boolean = false
  isSportsShowing = false
  isFamilyExpanded = true
  showFamilySubmenu: boolean = false
  isFamilyShowing = false
  isCultureExpanded = true
  showCultureSubmenu: boolean = false
  isCultureShowing = false

  constructor(
    private dialogFactoryService: DialogFactoryService,
    public translate: TranslateService,
    private categoriesService: CategoriesService,
    private subCategoriesService: SubCategoriesService,
    private eventsService: EventService,
    private router: Router
  ) {}

  async ngOnInit() {
    await this.getCategories()
  }

  private openDialog(dialogData: DialogData): void {
    this.dialog = this.dialogFactoryService.open(dialogData)
    this.closeDialogAction()
  }

  private closeDialogAction(): void {
    this.dialog.close().subscribe(data => {
      this.closing.emit(true)
    })
  }

  private dispatchDialog() {
    this.openDialog({
      template: this.mobileSearchDialogTemplate
    })
  }

  public onCloseMenu(): void {
    this.dialog.closeDialog()
  }

  private async getCategories() {
    this.categories = []
    lastValueFrom(await this.categoriesService.getAllCategories()).then(res => {
      for (const category of res.category) {
        const category2Show = {
          id: category.id,
          name: category.name,
          subcategories: category.subcategories
        }
        this.categories.push(category2Show)
      }
    })
  }

  private async getSubCategories() {
    this.subCategories = []
    lastValueFrom(await this.subCategoriesService.getAllSubCategories()).then(res => {
      for (const subcategory of res.subcategorie) {
        const subcategory2Show = {
          id: subcategory.id,
          name: subcategory.name,
          category: subcategory.category
        }
        this.subCategories.push(subcategory2Show)
      }
    })
  }

  /* public async openCategory(category: any) {
    this.showSubcategories = true
    let difference: any
    const subCategories = category.subcategories
    if (this.openSubc) {
      difference = [
        ...this.getDifference(subCategories, this.subList),
        ...this.getDifference(this.subList, subCategories)
      ]
      if (difference?.length === 0) {
        this.openSubc = !this.openSubc
      } else {
        this.subList = subCategories
      }
    } else {
      this.openSubc = !this.openSubc
      this.subList = subCategories
    }
  } */

  closeCategory() {
    this.showSubcategories = false
  }

  public async getEventsSubByCategory(subCategory: any) {
    if (this.eventsFiltered.length != 0) {
      this.eventsFiltered = []
    }
    let picture = APP_IMAGES.LOGO
    lastValueFrom(await this.eventsService.getEventsBySubCategory2(subCategory)).then(res => {
      if (this.router.url == '/filter') {
        this.eventsFiltered = []
      }
      for (const evento of res.event) {
        if (evento.picture !== null) {
          picture = evento.picture.url
        }
        const event2Show = {
          id: evento.id,
          imageUrl: picture,
          headerUrl: picture,
          title: evento.name,
          dateText: new Date(evento.initDate).toLocaleString('es-ES', {
            year: 'numeric',
            day: 'numeric',
            month: 'short',
            weekday: 'short'
          }),
          date: evento.initDate,
          time: evento.initTime,
          place: evento.enclosure.name,
          city: evento.enclosure.address.city,
          address: evento.enclosure.address,
          priceFrom: 10,
          tags: evento.tags,
          description: evento.description,
          sessions: evento.sessions
        }
        this.eventsFiltered.push(event2Show)
      }
      this.goFilterPage()
      this.onCloseMenu()
    })
  }

  public goFilterPage(): void {
    this.router.navigate([APP_ROUTES.FILTER], {
      state: { loadfilter: this.eventsFiltered }
    })
  }

  public showSubMenus(category: any) {
    this.showSubmenu = !this.showSubmenu
    this.categorySelected = category.name
  }
}
