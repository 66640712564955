<ng-template #registerDialogTemplate>
  <div class="register-dialog">
    <img [src]="APP_IMAGES.LOGOV" class="register-dialog__logo" />

    <h3 class="register-dialog__title">{{ 'TITLES.REGISTER' | translate }}</h3>

    <form
      class="register-dialog__form"
      [formGroup]="registerForm"
      (ngSubmit)="onSubmit(registerForm)">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'LABELS.EMAIL' | translate }}</mat-label>
        <input type="email" matInput formControlName="email" />
        <mat-error *ngIf="isSubmitted && fc['email'].errors && fc['email'].errors['required']">
          {{ 'ERRORS.EMAIL.REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'LABELS.NAME' | translate }}</mat-label>
        <input matInput formControlName="name" />
        <mat-error *ngIf="isSubmitted && fc['name'].errors && fc['name'].errors['required']">
          {{ 'ERRORS.NAME.REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>

      <div class="register-dialog__form-row">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'LABELS.FIRST_SURNAME' | translate }}</mat-label>
          <input matInput formControlName="surname1" />
          <mat-error
            *ngIf="isSubmitted && fc['surname1'].errors && fc['surname1'].errors['required']">
            {{ 'ERRORS.SURNAME.REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>{{ 'LABELS.SECOND_SURNAME' | translate }}</mat-label>
          <input matInput formControlName="surname2" />
        </mat-form-field>
      </div>

      <mat-form-field appearance="fill" class="password-confirm-field">
        <mat-label>{{ 'LABELS.PASSWORD' | translate }}</mat-label>
        <input type="password" matInput formControlName="password" />
        <mat-error
          *ngIf="isSubmitted && fc['password'].errors && fc['password'].errors['required']">
          {{ 'ERRORS.PASSWORD.REQUIRED' | translate }}
        </mat-error>
        <mat-error
          *ngIf="isSubmitted && fc['password'].errors && fc['password'].errors['pattern']"
          style="white-space: pre-wrap">
          {{ 'ERRORS.PASSWORD.INVALID' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'LABELS.PASSWORD_CONFIRM' | translate }}</mat-label>
        <input type="password" matInput formControlName="passwordConfirm" />
        <mat-error
          *ngIf="
            isSubmitted && fc['passwordConfirm'].errors && fc['passwordConfirm'].errors['required']
          ">
          {{ 'ERRORS.PASSWORD.REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>

      <div class="register-dialog__checkbox-row">
        <div class="register-dialog__checkbox">
          <mat-checkbox formControlName="acceptTerms"></mat-checkbox>
        </div>
        <mat-label
          >{{ 'LABELS.TERMS' | translate }}
          <a mat-button class="register-dialog__link-button" (click)="goToPrivacyPolicy()">
            <span> {{ 'TITLES.PRIVACY_POLICY' | translate }}</span>
          </a>
          {{ 'LABELS.TERMS2' | translate }}
          <a mat-button class="register-dialog__link-button" (click)="goToUseTerms()">
            <span> {{ 'TITLES.USE_TERMS' | translate }}</span>
          </a>
          {{ 'LABELS.TERMS3' | translate }}
        </mat-label>
      </div>
      <mat-error *ngIf="isSubmitted && fc['acceptTerms'].errors">
        {{ 'ERRORS.TERMS.REQUIRED' | translate }}
      </mat-error>

      <div class="register-dialog__checkbox-row2">
        <div class="register-dialog__checkbox">
          <mat-checkbox formControlName="acceptCommunications"></mat-checkbox>
        </div>
        <mat-label>{{ 'LABELS.COMUNICATIONS' | translate }}</mat-label>
      </div>

      <button type="submit" mat-flat-button class="register-dialog__btn">
        {{ 'BUTTONS.REGISTER' | translate }}
      </button>
    </form>

    <div class="register-dialog__links">
      <a mat-button class="register-dialog__link">
        {{ 'LABELS.HAVE_ACCOUNT' | translate
        }}<span (click)="onLoginUser()">{{ 'TITLES.INIT_SESSION' | translate }}</span>
      </a>
    </div>
  </div>
</ng-template>
