import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  public city: string = 'España'

  constructor(private _http: HttpClient) {}
  public setCity(str: string): void {
    this.city = str
  }
  public getCity(): string {
    return this.city
  }
}
