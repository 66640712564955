import { Component, EventEmitter, Input, Output } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ISelect } from 'src/app/shared/models/select.model'

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent {
  @Input() options: Array<ISelect>
  @Input() label: string

  @Output() selectedValue: string
  @Output() numberOfTicketsSelected = new EventEmitter<{ numberOfTickets: number }>()

  constructor(public translate: TranslateService) {}

  onNumberOfTicketsChange(selected: any) {
    this.numberOfTicketsSelected.emit({ numberOfTickets: selected.value + 1 })
  }
}
