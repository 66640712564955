import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { Observable, tap } from 'rxjs'
import Swal from 'sweetalert2'
import { UserService } from '../services/user.service'

@Injectable()
export class ExpiredSessionInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private userService: UserService
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
          }
        },
        error => {
          if (error.status == 401 && error.error.message.length == 26) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'center',
              showConfirmButton: true,
              timerProgressBar: true,
              width: '50%',
              buttonsStyling: true,
              confirmButtonColor: '#5db2e4'
            })
            Toast.fire({
              title: 'Sesión caducada.',
              text: 'La sesión ha expirado, vuelva a realizar el login.',
              confirmButtonText: 'Continuar'
            }).then(async () => {
              this.userService.logOut()
              if (this.router.url != '/') {
                this.router.navigate(['/'])
              }
            })
          }
        }
      )
    )
  }
}
