<mat-card
  class="event-card"
  *ngIf="event"
  [ngClass]="{ lighted: lightMode, home: isHome }"
  style="cursor: pointer">
  <img
    class="event-card__image"
    mat-card-image
    [src]="event.headerUrl"
    alt="Foto de evento"
    (click)="goToEvent()" />
  <mat-card-content
    (click)="goToEvent()"
    class="event-card__content"
    [ngClass]="{ lighted: lightMode, home: isHome }">
    <!-- <span class="event-card__content--title">{{ event.title }}</span> -->
    <!-- <span class="event-card__content--title__expanded" *ngIf="extendedView">
      {{ title }}
      <label
        *ngIf="extendedView && title.length > 30"
        class="event-card__content--more"
        (click)="viewMore()"
        style="cursor: pointer"
        >Ver menos</label
      >
    </span> -->
    <span class="event-card__content--title" *ngIf="!extendedView">
      {{ croppedTitle }}
      <!-- <label
        *ngIf="!extendedView && title.length > 30"
        class="event-card__content--more"
        (click)="viewMore()"
        style="cursor: pointer"
        >Ver más</label
      > -->
    </span>

    <!--<span class="event-card__content--subtitleDate">Desde {{ event.dateText }}</span>-->
    <span class="event-card__content--subtitleDate">Desde {{ event.dateText.slice(4) }} </span>
    <span class="event-card__content--subtitle">{{ event.place }}</span>
  </mat-card-content>
  <mat-card-footer
    class="event-card__footer"
    *ngIf="!isHome"
    [ngClass]="{ lighted: lightMode, home: isHome }">
    <span>Desde {{ event.priceFrom | currency : 'EUR' : 'symbol' : '1.0' }}</span>
    <mat-icon class="event-card__footer--arrow" (click)="goToEvent()">chevron_right</mat-icon>
  </mat-card-footer>
</mat-card>
