import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { APP_ROUTES } from 'src/app/shared/const/routes.const'
import { IEvent } from '../../models/events.model'

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent implements OnInit {
  @Input() event: IEvent
  @Input() lightMode: boolean = false
  @Input() isHome: boolean = false
  public extendedView: boolean = false
  public croppedTitle: string
  public title: string

  constructor(private router: Router, public translate: TranslateService) {}

  ngOnInit(): void {
    let maxChar = 0
    this.title = this.event.title
    if (window.innerWidth > 1200) {
      /* (window.matchMedia('(max-width: 767px)').matches) */ maxChar = 40
    }
    if (window.innerWidth <= 1200 && window.innerWidth > 768) {
      maxChar = 20
    }
    if (window.innerWidth <= 768) {
      maxChar = 20
    }
    if (this.title.length > maxChar) {
      this.croppedTitle = this.title.slice(0, maxChar) + '...'
    } else {
      this.croppedTitle = this.title
    }
  }

  public viewMore(): void {
    this.extendedView = !this.extendedView
  }

  public goToEvent(): void {
    this.router.navigate([APP_ROUTES.EVENT_DETAIL, this.event.id]).then(() => {
      window.location.reload()
    })
  }
}
