<swiper class="swiper" [config]="carouselConfig">
  <div class="swiper-wrapper">
    <div class="swiper-slide" *ngFor="let event of eventsData">
      <app-event-card [event]="event" [lightMode]="lightMode" [isHome]="isHome"></app-event-card>
    </div>
  </div>
  <div class="swiper-pagination"></div>
  <div class="swiper-button-next"></div>
  <div class="swiper-button-prev"></div>
</swiper>
