<mat-date-range-input [style.visibility]="'hidden'" [formGroup]="range" [rangePicker]="picker">
  <input matStartDate matInput formControlName="start" />
  <input matEndDate matInput formControlName="end" />
</mat-date-range-input>
<mat-date-range-picker #picker (closed)="onClose()">
  <mat-date-range-picker-actions>
    <button mat-button matDateRangePickerCancel (click)="onDelete()">
      {{ 'BUTTONS.DELETE' | translate }}
    </button>
    <button mat-raised-button color="primary" matDateRangePickerApply (click)="onApply()">
      {{ 'BUTTONS.APPLY' | translate }}
    </button>
  </mat-date-range-picker-actions>
</mat-date-range-picker>
