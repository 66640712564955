<app-popover-container>
  <div class="popover-location">
    <div class="popover-location__box">
      <button mat-flat-button (click)="closePopover(); getLocation()">
        <mat-icon class="popover-location__btn-icon">my_location</mat-icon>
        Usar ubicación actual
      </button>
      <button mat-flat-button (click)="showAllEvents()">
        <mat-icon class="popover-location__btn-icon">place</mat-icon> Todas las ubicaciones
      </button>
    </div>
    <mat-divider class="divider dark"></mat-divider>
    <app-search-input
      [searchData]="citiesList"
      placeholder="Busca por ciudad"
      [origen]="origen"
      (closePopover)="closePopover()"></app-search-input>
  </div>
</app-popover-container>
