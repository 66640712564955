import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core'
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { DialogFactoryService } from 'src/app/shared/components/dialog/dialog-factory.service'
import { DialogService } from 'src/app/shared/components/dialog/dialog.service'
import { APP_IMAGES } from 'src/app/shared/const/images.const'
import { APP_ROUTES } from 'src/app/shared/const/routes.const'
import { DialogData } from 'src/app/shared/models/dialog-data.model'
import { UserService } from 'src/app/shared/services/user.service'

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  @Input()
  set open(value: boolean) {
    if (value) {
      this.dispatchDialog()
    }
  }
  @Output() closing = new EventEmitter<boolean>()
  @Output() login = new EventEmitter<boolean>()

  @ViewChild('registerDialogTemplate')
  registerDialogTemplate: TemplateRef<any>

  get fc(): { [key: string]: AbstractControl } {
    return this.registerForm.controls
  }

  public readonly APP_IMAGES = APP_IMAGES
  public registerForm: FormGroup
  public isSubmitted: boolean = false

  private dialog: DialogService
  constructor(
    private dialogFactoryService: DialogFactoryService,
    public translate: TranslateService,
    private fb: FormBuilder,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.initForm()
  }

  private initForm(): void {
    this.registerForm = this.fb.group({
      email: ['', Validators.required],
      name: ['', Validators.required],
      surname1: ['', Validators.required],
      surname2: [''],
      businessName: [''],
      password: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!\"#$%&'()*+,-./:;<=>?@[\]^_`{|}~¡€])[A-Za-z\d!\"#$%&'()*+,-./:;<=>?@[\]^_`{|}~¡€]{8,}$/
          )
        ]
      ],
      passwordConfirm: ['', Validators.required],
      acceptTerms: [false, Validators.requiredTrue],
      acceptCommunications: [false]
    })
  }

  public onSubmit(form: FormGroup): void {
    this.isSubmitted = true
    if (this.registerForm.valid) {
      let user = this.userService.setNewUser()
      user.email = this.registerForm.get('email')?.value
      user.userName = this.registerForm.get('name')?.value
      user.name = this.registerForm.get('name')?.value
      user.surname1 = this.registerForm.get('surname1')?.value
      user.surname2 = this.registerForm.get('surname2')?.value
      user.businessName = this.registerForm.get('businessName')?.value
      user.password = this.registerForm.get('password')?.value
      user.acceptCommunications = this.registerForm.get('acceptCommunications')?.value

      this.userService.signUp(user).subscribe({
        next: async response => {
          let snackBarRef = this.snackBar.open('register succeed')
          this.dialog.closeDialog()
        },
        complete: () => {},
        error: error => {}
      })
    }
  }

  public goToUseTerms(): void {
    this.dialog.closeDialog()
    this.router.navigate([APP_ROUTES.USE_TERMS])
  }

  public goToPrivacyPolicy(): void {
    this.dialog.closeDialog()
    this.router.navigate([APP_ROUTES.PRIVACY_POLICY])
  }

  private dispatchDialog() {
    this.openDialog({
      template: this.registerDialogTemplate
    })
  }

  private openDialog(dialogData: DialogData): void {
    this.dialog = this.dialogFactoryService.open(dialogData)
    this.closeDialogAction()
  }

  private closeDialogAction(): void {
    this.dialog.close().subscribe(data => {
      this.closing.emit(true)
    })
  }

  onLoginUser() {
    this.login.emit(true)
    this.dialog.closeDialog()
  }
}
