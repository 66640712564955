import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { GoogleMapsModule } from '@angular/google-maps'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatChipsModule } from '@angular/material/chips'
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import { MatDividerModule } from '@angular/material/divider'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu'
import { MatSelectModule } from '@angular/material/select'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatStepperModule } from '@angular/material/stepper'
import { MatTabsModule } from '@angular/material/tabs'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatTreeModule } from '@angular/material/tree'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { TranslateModule } from '@ngx-translate/core'
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper'
import { BannerComponent } from './components/banner/banner.component'
import { CalendarBoxBigComponent } from './components/calendar-box-big/calendar-box-big.component'
import { CalendarBoxComponent } from './components/calendar-box/calendar-box.component'
import { CalendarInlineRangeComponent } from './components/calendar-inline-range/calendar-inline-range.component'
import { CategoryCardComponent } from './components/category-card/category-card.component'
import { ChipComponent } from './components/chip/chip.component'
import { PopoverLocationComponent } from './components/custom-popovers/popover-location/popover-location.component'
import { LoginComponent } from './components/dialog/components/login/login.component'
import { MobileMenuComponent } from './components/dialog/components/mobile-menu/mobile-menu.component'
import { MobileSearchComponent } from './components/dialog/components/mobile-search/mobile-search.component'
import { RecoverPasswordComponent } from './components/dialog/components/recover-password/recover-password.component'
import { RegisterComponent } from './components/dialog/components/register/register.component'
import { DialogComponent } from './components/dialog/dialog.component'
import { EventCardComponent } from './components/event-card/event-card.component'
import { FooterComponent } from './components/footer/footer.component'
import { HeaderComponent } from './components/header/header.component'
import { PopoverModule } from './components/popover/popover.module'
import { SearchInputComponent } from './components/search-input/search-input.component'
import { SelectComponent } from './components/select/select.component'
import { SwiperComponent } from './components/swiper/swiper.component'

const materialModules = [
  MatToolbarModule,
  MatButtonModule,
  MatIconModule,
  MatInputModule,
  MatFormFieldModule,
  MatCardModule,
  MatDividerModule,
  MatAutocompleteModule,
  MatTabsModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatChipsModule,
  MatSelectModule,
  MatDialogModule,
  MatMenuModule,
  MatTreeModule,
  MatSidenavModule,
  MatListModule,
  MatCheckboxModule,
  MatStepperModule,
  MatGridListModule,
  GoogleMapsModule
]
const customComponents = [
  HeaderComponent,
  SearchInputComponent,
  EventCardComponent,
  CalendarBoxComponent,
  CalendarBoxBigComponent,
  PopoverLocationComponent,
  CategoryCardComponent,
  BannerComponent,
  FooterComponent,
  SwiperComponent,
  CalendarInlineRangeComponent,
  ChipComponent,
  SelectComponent,
  DialogComponent,
  LoginComponent,
  RegisterComponent,
  RecoverPasswordComponent
]

const customModules = [PopoverModule]

const vendorModules = [NgxUsefulSwiperModule, TranslateModule]

@NgModule({
  declarations: [...customComponents, MobileSearchComponent, MobileMenuComponent],
  imports: [
    ...materialModules,
    ...vendorModules,
    ...customModules,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule
  ],
  exports: [...customComponents, ...materialModules, ...vendorModules, ...customModules],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'es-ES' }]
})
export class SharedModule {}
