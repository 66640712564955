<ng-template #mobileSearchDialogTemplate>
  <h3 class="login-dialog__title">
    <div>
      <fa-icon class="glass-icon" [icon]="mGlass"></fa-icon>
      <input
      
        type="search"
        class="search-form-field"
        name="gsearch"
        
        [(ngModel)]="search"
        [formControl]="searchCtrl"
        (ngModelChange)="searchF()" />
    </div>
    <!--<mat-form-field appearance="fill">
      <mat-label>Artista, evento o recinto</mat-label>
      <input matInput type="text" [(ngModel)]="search" (ngModelChange)="searchF()" />
      <mat-icon matPrefix class="search-input__icon">search</mat-icon>
    </mat-form-field>-->
  </h3>
  <mat-divider class="divider dark"></mat-divider>
  <div class="results">
    <mat-label>Eventos</mat-label>
    <mat-option
      class="search-input__card"
      *ngFor="let searchObj of filteredSearch | async | slice : 0 : 5"
      [value]="searchObj.title"
      (onSelectionChange)="goToEvent(searchObj.id)" >
      <app-calendar-box [date]="searchObj.date"></app-calendar-box>
      <div class="search-input__card--right">
        <span class="search-input__card--title">{{ searchObj.title }}</span>
        <div class="search-input__card--subtitle">
          <!--<small class="strong">
            {{ searchObj.priceFrom | currency : 'EUR' : 'symbol' : '1.0' }} -
          </small>-->
          <small>{{ searchObj.time | slice:0:-3 }} -</small>
          <small>&nbsp;{{ searchObj.place }}</small>
        </div>
      </div>
    </mat-option>
  </div>
  <div class="search-input__footerMessage">
    <ng-container *ngIf="(filteredSearch | async) as searchArray">
      <mat-label  *ngIf="searchArray.length > 5" (click)="goToAllEventsSearched()" >Ver todos los resultados</mat-label>
    </ng-container>
  </div>
</ng-template>
