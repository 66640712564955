import { Component, Input } from '@angular/core'
import { DatesHelper } from '../../helpers/dates.helper'

@Component({
  selector: 'app-calendar-box-big',
  templateUrl: './calendar-box-big.component.html',
  styleUrls: ['./calendar-box-big.component.scss']
})
export class CalendarBoxBigComponent {
  @Input() date: string

  constructor(private datesHelper: DatesHelper) {}

  public getMonthReduced(): string {
    return this.datesHelper.getMonthReducedText(this.date)
  }

  public getDay(): string {
    return this.datesHelper.getDay(this.date)
  }
}
