import { Component, Input } from '@angular/core'
import { Router } from '@angular/router'
import { lastValueFrom } from 'rxjs'
import { APP_IMAGES } from 'src/app/shared/const/images.const'
import { APP_ROUTES } from 'src/app/shared/const/routes.const'
import { EventService } from 'src/app/shared/services/event.service'

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent {
  @Input() tags: Array<string>
  eventsFiltered: any[] = []

  constructor(private router: Router, public eventService: EventService) {}

  public async getEventsByCategory2(eventCategory: any) {
    let picture = APP_IMAGES.LOGO
    await lastValueFrom(await this.eventService.getEventsByCategory2(eventCategory)).then(
      async res => {
        for (const evento of res.event) {
          if (evento.picture !== null) {
            picture = evento.picture.url
          }
          const event2Show = {
            id: evento.id,
            imageUrl: picture,
            headerUrl: picture,
            title: evento.name,
            dateText: new Date(evento.initDate).toLocaleString('es-ES', {
              year: 'numeric',
              day: 'numeric',
              month: 'short',
              weekday: 'short'
            }),
            date: evento.initDate,
            // time: evento.initTime,
            place: evento.enclosure.name,
            // city: evento.enclosure.address.city,
            // address: evento.enclosure.address,
            // priceFrom: 10,
            tags: evento.tags
            // description: evento.description,
            // sessions: evento.sessions
          }
          this.eventsFiltered.push(event2Show)
        }
      }
    )

    await lastValueFrom(await this.eventService.getEventsBySubCategory(eventCategory)).then(res => {
      for (const evento2 of res.event) {
        if (evento2.picture !== null) {
          picture = evento2.picture.url
        }
        const event2Show = {
          id: evento2.id,
          imageUrl: picture,
          headerUrl: picture,
          title: evento2.name,
          dateText: new Date(evento2.initDate).toLocaleString('es-ES', {
            year: 'numeric',
            day: 'numeric',
            month: 'short',
            weekday: 'short'
          }),
          date: evento2.initDate,
          time: evento2.initTime,
          place: evento2.enclosure.name,
          city: evento2.enclosure.address.city,
          address: evento2.enclosure.address,
          priceFrom: 10,
          tags: evento2.tags,
          description: evento2.description,
          sessions: evento2.sessions
        }
        this.eventsFiltered.push(event2Show)
      }
    })
    this.goFilterPage()
  }
  public goFilterPage(): void {
    this.router.navigate([APP_ROUTES.FILTER], {
      state: { loadfilter: this.eventsFiltered }
    })
  }
}
