<div (mouseleave)="closeCategory()">
  <mat-toolbar class="header">
    <fa-icon class="header__bars-icon" [icon]="bars" (click)="openMobileMenuDialog()"></fa-icon>
    <img
      src="assets/img/ticketernalLogoBorderless.png"
      class="header__logo"
      (click)="goToHomePage()" />
    <div class="header__block">
      <div class="categories-block" *ngFor="let category of categories">
        <a mat-button class="header__link" (mouseenter)="openCategory(category)">
          {{ category.name }}
        </a>
      </div>
      <!-- <a mat-button class="header__link" (click)="openMusicSubc()">Conciertos</a>
    <a mat-button class="header__link" (click)="openScenicArtsSubc()">Artes escénicas</a>
    <a mat-button class="header__link" (click)="openFestivalsSubc()">Festivales</a>
    <a mat-button class="header__link" (click)="openSportsSubc()">Deportes</a>
    <a mat-button class="header__link" (click)="openFamilySubc()">Familias</a>
    <a mat-button class="header__link" (click)="openCultureSubc()">Cultura y más</a> -->
      <div class="header__search">
        <app-search-input
          [searchData]="featuredEvents"
          [calendarBox]="true"
          placeholder=""></app-search-input>
      </div>
      <!--<a mat-button class="header__link" (click)="goToHelpPage()">Ayuda</a>-->
      <a *ngIf="!isLogged" mat-flat-button class="header__btn" (click)="openLoginDialog()">
        Iniciar sesión
      </a>
      <button
        class="header__btn--mobile"
        mat-mini-fab
        aria-label="Icono Iniciar Sesion"
        (click)="openMobileSearchDialog()">
        <fa-icon class="header__bars-icon-button" [icon]="mGlass"></fa-icon>
      </button>
      <!--Boton negro cuando estas logeado-->
      <a *ngIf="isLogged" mat-button class="header__btn--mobileProfile" [matMenuTriggerFor]="menu">
        <img
          *ngIf="userImage; else defaultIcon"
          [src]="userImage"
          alt="Avatar del usuario"
          class="avatar-image" />
        <ng-template #defaultIcon>
          <mat-icon>person</mat-icon>
        </ng-template>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="goToTickets()">
            <mat-icon>receipt</mat-icon>Mis Tickets
          </button>
          <button mat-menu-item (click)="goToProfile()">
            <mat-icon>person</mat-icon><ng-container *ngIf="!isLogged">Iniciar sesion</ng-container
            ><ng-container *ngIf="isLogged">Mi cuenta</ng-container>
          </button>
          <button mat-menu-item (click)="logOut()"><mat-icon>close</mat-icon>Cerrar sesión</button>
        </mat-menu>
      </a>
      <!--Boton blanco cuando la pantalla es pequeña aunque estes logeado-->
      <a mat-button class="header__btn--mobileProfileSmall" [matMenuTriggerFor]="menu">
        <img
          *ngIf="userImage; else defaultIcon"
          [src]="userImage"
          alt="Avatar del usuario"
          class="avatar-image" />
        <ng-template #defaultIcon>
          <mat-icon>person</mat-icon>
        </ng-template>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="goToTickets()"><mat-icon>receipt</mat-icon>Tickets</button>
          <button mat-menu-item (click)="goToProfile()">
            <mat-icon>person</mat-icon><ng-container *ngIf="!isLogged">Iniciar sesion</ng-container
            ><ng-container *ngIf="isLogged">Mi cuenta</ng-container>
          </button>
          <button mat-menu-item (click)="logOut()"><mat-icon>close</mat-icon>Cerrar sesión</button>
        </mat-menu>
      </a>
    </div>
  </mat-toolbar>
  <mat-toolbar class="sec_header" *ngIf="showSubcategories">
    <div class="subcat__block" *ngFor="let subcategory of subList">
      <div
        class="mdc-card"
        style="cursor: pointer"
        (click)="getEventsSubByCategory(subcategory.name)">
        <a>{{ subcategory.name }}</a>
      </div>
    </div>
  </mat-toolbar>
</div>
<app-login
  [open]="isOpenLoginDialog"
  (closing)="isLoginDialogClosed($event)"
  (registerUser)="openRegisterDialog()"
  (recoverPassword)="openRecoverDialog()"></app-login>
<app-register
  [open]="isOpenRegisterDialog"
  (closing)="isRegisterDialogClosed($event)"
  (login)="openLoginDialog()"></app-register>
<app-recover-password
  [open]="isOpenRecoverDialog"
  (closing)="isRecoverDialogClosed($event)"
  (login)="openLoginDialog()"></app-recover-password>
<app-mobile-menu
  [open]="isOpenMobileMenuDialog"
  (closing)="isMobileMenuDialogClosed($event)"></app-mobile-menu>
<app-mobile-search
  [open]="isOpenMobileSearchDialog"
  (closing)="isMobileSearchDialogClosed($event)"></app-mobile-search>
