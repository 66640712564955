import { Directive, ElementRef, EventEmitter, HostListener, Output, Renderer2 } from '@angular/core'
import * as d3 from 'd3'

@Directive({
  selector: '[appSvgInit]'
})
export class SvgInitDirective {
  @Output() newItemEvent: EventEmitter<any> = new EventEmitter<any>()
  private points: Array<[number, number]> = []
  private currentLine?: d3.Selection<SVGLineElement, unknown, null, any>
  private draggingPoints: number[][] = []
  private draggingElement?: d3.Selection<SVGPolygonElement, unknown, null, any>
  private currentPoint: { x: number; y: number } = { x: 0, y: 0 }
  private mousedown = false
  private created = false
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}
  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent): void {
    // Create the polygon only if we have three or more points
    // and we are clicking on the rectangular helping shape
    if (this.created) {
      this.mousedown = true
      let coordinates = this.getMousePosition(event)
      let target = event.target as SVGPolygonElement
      if (target.tagName === 'polygon') {
        this.currentPoint = coordinates
        this.draggingElement = d3.select(target)
        this.draggingPoints = this.getPointsFromPolygon()
      }
    } else {
      if ((event.target as HTMLElement).tagName === 'rect' && this.points.length >= 3) {
        this.createPolygon()
        this.clearHelperShapes()
      } else {
        // Otherwise, create another helping line or rectangular
        this.addHelperShapes(event)
      }
    }
  }
  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent): void {
    if (this.created) {
      let coordinates = this.getMousePosition(event)
      if (!!this.draggingElement && this.mousedown) {
        document.body.style.cursor = 'move'
        let x = coordinates.x - this.currentPoint.x
        let y = coordinates.y - this.currentPoint.y
        let h = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2))
        let angle = this.angleFromCoordinates(x, y)
        var newPoints = this.polygonTranslate(this.draggingPoints, angle, h)
        this.draggingElement.attr('points', newPoints.join(' '))
      }
    } else {
      let coordinates = this.getMousePosition(event)
      if ((event.target as HTMLElement).tagName === 'rect' && this.points.length >= 3) {
        this.renderer.setAttribute(event.target, 'stroke', '#0784fa')
      } else {
        let rect: SVGRectElement | null = document.querySelector('rect')
        if (rect) {
          this.renderer.setAttribute(rect, 'stroke', 'transparent')
        }
      }
      // Move line by changing the coordinates
      if (this.currentLine) {
        this.currentLine.attr('x2', coordinates.x).attr('y2', coordinates.y)
      }
    }
  }
  /**
   * After each mouse click, we add a point into the array of polygon coordinates
   * and insert the next helping line.
   * @param event Mouse Event
   */
  private addHelperShapes(event: MouseEvent) {
    let coordinates = this.getMousePosition(event)
    this.points.push([coordinates.x, coordinates.y])
    if (this.points.length == 1) {
      d3.select(this.elementRef.nativeElement)
        .append('rect')
        .attr('x', coordinates.x - 5)
        .attr('y', coordinates.y - 5)
        .attr('width', '10')
        .attr('height', '10')
        .attr('fill', 'transparent')
        .attr('class', 'help')
    }
    this.currentLine = d3
      .select(this.elementRef.nativeElement)
      .insert('line', ':nth-child(1)')
      .attr('x1', coordinates.x)
      .attr('x2', coordinates.x)
      .attr('y1', coordinates.y)
      .attr('y2', coordinates.y)
      .attr('stroke', '#0784fa')
      .attr('class', 'help')
  }
  /**
   * Create a polygon shape
   * @param event Mouse Event
   */
  private createPolygon() {
    d3.select(this.elementRef.nativeElement)
      .append('polygon')
      .attr('points', this.points.join(' '))
      .attr('fill', 'lightgrey')
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
    this.newItemEvent.emit(this.points.join(' '))
    this.created = true
  }
  /**
   * Get the mouse coordinates relative to the SVG element
   * @param event Mouse Event
   */
  private getMousePosition(event: MouseEvent) {
    return {
      x: event.offsetX,
      y: event.offsetY
    }
  }
  /**
   * Clear the helping lines and rectangular
   * @param event Mouse Event
   */
  private clearHelperShapes() {
    document.querySelectorAll('.help').forEach(element => {
      element.remove()
    })
    this.points = []
  }

  /*@HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent): void {
    this.mousedown = true;
    let coordinates = this.getMousePosition(event);
    let target = (event.target as SVGPolygonElement);
    if (target.tagName === 'polygon') {
      this.currentPoint = coordinates;
      this.draggingElement = d3.select(target);
      this.draggingPoints = this.getPointsFromPolygon();
    }
  }
  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent): void {
    let coordinates = this.getMousePosition(event);
    if (!!this.draggingElement && this.mousedown) {
        document.body.style.cursor = 'move';
        let x = coordinates.x - this.currentPoint.x;
        let y = coordinates.y - this.currentPoint.y;
        let h = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
        let angle = this.angleFromCoordinates(x, y);
        var newPoints = this.polygonTranslate(this.draggingPoints, angle, h);
        this.draggingElement.attr('points', newPoints.join(" "));
    }
  }*/
  @HostListener('mouseup', ['$event'])
  onMouseUp(event: MouseEvent): void {
    this.mousedown = false
    this.draggingElement = undefined
    this.renderer.setStyle(document.body, 'cursor', 'initial')
  }
  /**
   * Get the angle from two coordinates
   */
  angleFromCoordinates(x: number, y: number) {
    let rad = Math.atan2(y, x)
    var pi = Math.PI
    let angle = Math.round(rad * (180 / pi))
    if (angle < 0) {
      angle = angle + 360
    }
    return angle
  }
  /**
   * Translate current polygon into a new one
   * @param polygon An array of polygon coordinates
   * @param angle The angle created by the polygon and the current mouse position
   * @param distance The distance from the polygon to the mouse position
   * @returns
   */
  polygonTranslate(polygon: number[][], angle: number, distance: number) {
    let p = []
    for (let i = 0, l = polygon.length; i < l; i++) {
      const r = (angle / 180) * Math.PI
      p[i] = [polygon[i][0] + distance * Math.cos(r), polygon[i][1] + distance * Math.sin(r)]
    }
    return p
  }
  /**
   * Extract the points of current polygon
   * @param event Mouse events
   */
  getPointsFromPolygon(): number[][] {
    let points: Array<{ x: number; y: number }> = this.draggingElement?.property('points')
    let pointsArray = []
    for (let i = 0; i < points.length; i++) {
      pointsArray.push([points[i].x, points[i].y])
    }
    return pointsArray
  }
}
