<!--<div class="footer">
  <div class="footer__row">
    <div class="footer__logo">
      <img [src]="APP_IMAGES.LOGOV" />
      <span class="footer__link-item--small">2022 Ticketernal</span>
    </div>

    <div class="footer__links">
      <a mat-button class="footer__link-item" (click)="goAboutUs()">{{
        'TITLES.ABOUT_US' | translate
      }}</a>
      <a mat-button class="footer__link-item">Ayuda</a>
      <a mat-button class="footer__link-item" (click)="goToSellTickets()"
        >Vende entradas con nosotros</a
      >
      <a mat-button class="footer__link-item">Publicidad</a>
    </div>
  </div>

  <div class="footer__row right">
    <div class="footer__links">
      <a mat-button class="footer__link-item--small" (click)="goLegal()">Aviso Legal</a>
      <a mat-button class="footer__link-item--small" (click)="goToPrivacyPolicy()">{{
        'TITLES.PRIVACY_POLICY' | translate
      }}</a>
      <a mat-button class="footer__link-item--small" (click)="goToUseTerms()">{{
        'TITLES.USE_TERMS' | translate
      }}</a>
    </div>
  </div>
</div>-->

<div class="footer__container">
  <div class="footer__logoArea">
    <div class="footer__logoArea_logo">
      <img src="assets/img/footer/starWhite.png" />
    </div>
    <div class="footer__logoArea_punchline">
      <img src="assets/img/footer/punchLine.png" />
    </div>
    <div class="footer__logoArea_followButtons">
      Síguenos
      <div class="footer__logoArea_followButtons_B">
        <a class="rrsslink" href=" https://www.instagram.com/ticketernal/" target="_blank">
          <img src="assets/img/footer/instagramLogo.svg" />
        </a>
        <a class="rrsslink" href=" https://www.linkedin.com/company/ticketernal" target="_blank">
          <img src="assets/img/footer/linkedInLogo.svg" />
        </a>
        <a
          href="https://www.tiktok.com/@ticketernal?is_from_webapp=1&sender_device=pc"
          target="_blank">
          <img class="rrsslinkt" src="assets/img/footer/tiktok.png" />
        </a>
        <a class="rrsslink" href="https://www.facebook.com/ticketernal" target="_blank">
          <img src="assets/img/footer/facebookLogo.svg" />
        </a>
      </div>
    </div>
    <div class="footer__logoArea_legalLinks">
      <a mat-button class="footer__link-item--small" (click)="goLegal()">Aviso Legal</a>|
      <a mat-button class="footer__link-item--small" (click)="goToPrivacyPolicy()">{{
        'TITLES.PRIVACY_POLICY' | translate
      }}</a
      >|
      <a mat-button class="footer__link-item--small" (click)="goToUseTerms()">{{
        'TITLES.USE_TERMS' | translate
      }}</a>
    </div>
  </div>
  <div class="footer__linksArea">
    <div class="footer__linksArea_links">
      <a mat-button (click)="goAboutUs()">{{ 'TITLES.ABOUT_US' | translate }}</a>
      <a mat-button>Ayuda</a>
      <a mat-button (click)="goToSellTickets()">Vende entradas con nosotros</a>
      <a mat-button (click)="goAdvertising()">Publicidad</a>
    </div>
    <div class="footer__linksArea_companyName">
      <img src="assets/img/footer/companyName.png" />
    </div>
    <div class="footer__linksArea_rightsReserved">
      ©2023 Fundación Obra Social de Castilla y León. Todos los derechos reservados
    </div>
  </div>
</div>
