import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Router } from '@angular/router'
import { Observable, Subject } from 'rxjs'
import { IUser } from 'src/app/shared/models/user.model'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private KEY_LOCAL_STORAGE_TOKEN = 'fnds::web_token'
  private KEY_LOCAL_STORAGE_LATEST_LANGUAGE = 'latest_language'
  private KEY_LOCAL_STORAGE_USER = 'fnds::current_user'
  private KEY_LOCAL_STORAGE_USER2 = 'fnds::user'
  private currentUserObservable = new Subject<boolean>()
  constructor(private _http: HttpClient, private snackBar: MatSnackBar, private router: Router) {}

  signUp(user: any): Observable<any> {
    return this._http.post(environment.apiUrl + '/users', user)
  }

  getAllUsers(): Observable<any> {
    return this._http.get(environment.apiUrl + '/users')
  }

  getUser(id: string): Observable<any> {
    return this._http.get(environment.apiUrl + '/users/' + id)
  }

  login(user: any): Observable<any> {
    return this._http.post(environment.apiUrl + '/auth/login', user)
  }

  login2(user: any): Observable<any> {
    return this._http.post(environment.apiUrl + '/auth/login2', user)
  }

  public async updateUser(id: string, user: any): Promise<Observable<any>> {
    return this._http.put(environment.apiUrl + '/users/' + id, user)
  }

  public async setToken(tokenValue: string) {
    localStorage.setItem(this.KEY_LOCAL_STORAGE_TOKEN, tokenValue)
  }

  public async setUser(userValue: any) {
    localStorage.setItem(this.KEY_LOCAL_STORAGE_USER, JSON.stringify(userValue))
    this.currentUserObservable.next(true)
  }

  public async logOut() {
    localStorage.removeItem(this.KEY_LOCAL_STORAGE_USER)
    localStorage.removeItem(this.KEY_LOCAL_STORAGE_TOKEN)
    localStorage.removeItem(this.KEY_LOCAL_STORAGE_USER2)
    this.currentUserObservable.next(false)
    return true
  }

  public async getToken() {
    let token = localStorage?.getItem(this.KEY_LOCAL_STORAGE_TOKEN)
    if (!token) {
      //let snackBarRef = this.snackBar.open('login')
      this.router.navigate(['/'])
      return 'error'
    }
    return token
  }

  public isUserAuthenticated(): boolean {
    let user = localStorage?.getItem(this.KEY_LOCAL_STORAGE_USER)
    if (!user) {
      return false
    } else {
      return true
    }
  }

  public getlocalStorageuser(): any {
    let user = localStorage?.getItem(this.KEY_LOCAL_STORAGE_USER)
    return user
  }

  public getObservableUser(): Observable<boolean> {
    return this.currentUserObservable.asObservable()
  }

  async createFile(file: File): Promise<Observable<any>> {
    const auth_token = await this.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    const formData: FormData = new FormData()
    formData.append('file', file)
    return this._http.post(environment.apiUrl + '/file', formData, { headers })
  }

  public async getLocalUserOld(): Promise<string> {
    let user = localStorage?.getItem(this.KEY_LOCAL_STORAGE_USER)
    if (!user) {
      let snackBarRef = this.snackBar.open('login')
      this.router.navigate(['/'])
      return 'error'
    } else {
      return user
    }
  }

  public async getLocalUser(): Promise<Observable<any>> {
    let user: any
    const token = await this.getToken()
    user = this._http.post(
      environment.apiUrl + '/auth/userByToken',
      {},
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    )
    return user
  }

  public setNewUser(): IUser {
    let user: IUser = {
      userName: '',
      email: '',
      name: '',
      surname1: '',
      surname2: '',
      businessName: '',
      password: '',
      location: ' ',
      phone: ' ',
      acceptCommunications: false,
      avatar: {
        url: '',
        relativeDirectory: '',
        filename: '',
        mimetype: '',
        originalName: '',
        title: '',
        description: '',
        size: 0
      },
      address: {
        address: '',
        number: '',
        floor: '',
        zip: '',
        city: '',
        geolocation: {
          lon: 0,
          lat: 0
        }
      },
      role: ['user']
    }
    return user
  }

  public recoverPassword(email: string): Observable<any> {
    const body = {
      email: email
    }
    return this._http.post(environment.apiUrl + '/auth/forgot-password', body)
  }

  public resetPassword(password: string, token: string): Observable<any> {
    const body = {
      password: password
    }
    return this._http.post(environment.apiUrl + '/auth/reset-password?token=' + token, body)
  }
}
