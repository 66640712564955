import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { DateAdapter } from '@angular/material/core'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { lastValueFrom } from 'rxjs'
import { APP_IMAGES } from '../../const/images.const'
import { APP_ROUTES } from '../../const/routes.const'
import { EventService } from '../../services/event.service'

@Component({
  selector: 'app-calendar-inline-range',
  templateUrl: './calendar-inline-range.component.html',
  styleUrls: ['./calendar-inline-range.component.scss']
})
export class CalendarInlineRangeComponent implements OnInit {
  public eventsFiltered: any[] = []
  @Input() set toggle(value: boolean) {
    if (value) {
      this.pickerElementRef.open()
    }
  }
  @Output() closing = new EventEmitter<boolean>()
  @ViewChild('picker') pickerElementRef: any

  public range = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null)
  })

  constructor(
    public translate: TranslateService,
    private router: Router,
    public eventsService: EventService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.getFirstDayOfWeek = () => 1
  }

  public ngOnInit() {
    this.getEvents()
  }

  public onClose(): void {
    this.closing.emit()
  }

  public onDelete(): void {
    this.range.reset()
  }
  public onApply(): void {
    this.filterEventsByDateRange()

    this.range.reset()
    this.router.navigate([APP_ROUTES.FILTER], {
      state: { loadfilter: this.eventsFiltered }
    })
  }

  private async getEvents() {
    let picture = APP_IMAGES.LOGO
    lastValueFrom(await this.eventsService.getAllEvents()).then(res => {
      for (const evento of res.event) {
        if (evento.picture !== null) {
          picture = evento.picture.url
        }
        const event2Show = {
          id: evento.id,
          imageUrl: picture,
          headerUrl: picture,
          title: evento.name,
          dateText: new Date(evento.initDate).toLocaleString('es-ES', {
            year: 'numeric',
            day: 'numeric',
            month: 'short',
            weekday: 'short'
          }),
          date: new Date(evento.initDate).toLocaleString('en-EN', {
            year: 'numeric',
            day: 'numeric',
            month: 'short'
          }),
          dateEnd: new Date(evento.endDate).toLocaleString('en-EN', {
            year: 'numeric',
            day: 'numeric',
            month: 'short'
          }),
          time: evento.initTime,
          place: evento.enclosure.name,
          city: evento.enclosure.address.city,
          address: evento.enclosure.address,
          priceFrom: 10,
          tags: evento.tags,
          description: evento.description,
          sessions: evento.sessions
        }

        this.eventsFiltered.push(event2Show)
      }
    })
  }
  private filterEventsByDateRange() {
    const startDate = new Date(this.range.controls['start'].value).toLocaleString('en-EN', {
      year: 'numeric',
      day: 'numeric',
      month: 'short'
    })
    const endDate = new Date(this.range.controls['end'].value).toLocaleString('en-EN', {
      year: 'numeric',
      day: 'numeric',
      month: 'short'
    })
    if (startDate && this.range.value.end != null) {
      this.eventsFiltered = this.eventsFiltered.filter(event => {
        const eventDate = new Date(event.date).getTime()
        const eventDateEnd = new Date(event.dateEnd).getTime()
        const start = new Date(startDate).getTime()
        const end = new Date(endDate).getTime()
        return eventDate <= end && eventDateEnd >= start
      })
    } else if (startDate) {
      this.eventsFiltered = this.eventsFiltered.filter(event => {
        const eventDate = new Date(event.date).getTime()
        const eventDateEnd = new Date(event.dateEnd).getTime()

        const start = new Date(startDate).getTime()

        return eventDate <= start && start <= eventDateEnd
      })
    }
  }
}
